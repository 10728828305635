import FullCalendarComponent from "@fullcalendar/react";
import React, {forwardRef, useEffect, useState} from "react";
import moment from "moment/moment";

const screenSize = window.document.body.offsetWidth;

export const FullCalendar = forwardRef(({children, ...props}, ref) =>{
    const [title, setTitle] = useState('')
    useEffect(() => {
        document.querySelector('.fc-toolbar-title').innerHTML ='&nbsp;'+title
                .replace(
                    "{{NL}}",
                    '<br/>&nbsp;'
                )
        document.querySelector('.fc-toolbar-title').style.width = '105px'
    }, [title]);

    useEffect(() => {
        if(screenSize<640) {
            //document.querySelector('.fc-timegrid-axis-chunk')?.style.setProperty('zoom', '0.75', 'important');
            setTimeout(()=> {
                console.log([...document.querySelectorAll('.fc-timegrid-slot.fc-timegrid-slot-minor')]);
                [...document.querySelectorAll('.fc-timegrid-slot.fc-timegrid-slot-minor')].map(el => {
                    console.log(el.style)
                    el?.style.setProperty('height', '0', 'important')
                });
            }, 100)
            //document.querySelector('.fc-timegrid-slot.fc-timegrid-slot-label.fc-timegrid-slot-minor')?.style.setProperty('height', '0', 'important');
        }
        if(screenSize<6) {
            document.querySelector('.fc-timegrid-axis-chunk>table>colgroup>col')?.style.setProperty('width', '48px', 'important');
            document.querySelector('.fc-scrollgrid.fc-scrollgrid-liquid>colgroup>col')?.style.setProperty('width', '48px', 'important');
            document.querySelector('.fc-timegrid .fc-scrollgrid-sync-table')?.style.setProperty('width', '48px', 'important');
            document.querySelector('.fc-timegrid .fc-scrollgrid-sync-table colgroup>col')?.style.setProperty('width', '48px', 'important');

        }
    }, [screenSize]);

    return (
        <FullCalendarComponent
            dayMinWidth={150}
            {...(ref?{ref}:{})}
            views={{
                timeGridWeek: { // name of view

                    titleFormat: ({date,start, end, }) => {
                        setTitle(`${moment(start.marker).format('YYYY/MM/DD')}{{NL}}${moment(end.marker).format('YYYY/MM/DD')}`)
                    }
                },
                dayGridMonth: { // name of view
                    titleFormat: ({date,start, end, }) => {
                        setTitle(`${moment(start.marker).format('YYYY/MM/DD')}{{NL}}${moment(end.marker).format('YYYY/MM/DD')}`)
                        return ``
                    }
                },
                listWeek: { // name of view
                    titleFormat: ({date,start, end, }) => {
                        setTitle(`${moment(start.marker).format('YYYY/MM/DD')}{{NL}}${moment(end.marker).format('YYYY/MM/DD')}`)
                        return ``
                    }
                }
            }}

            eventMaxStack={1}
            {...props}
        >{children || null}</FullCalendarComponent>
    )
})
