/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "../../../../../components/MDButton";
import {ChevronLeft, ChevronRight} from "@mui/icons-material";
import React, {useEffect, useRef, useState} from "react";
import {Paper} from "@mui/material";
import sha256 from "js-sha256";
import Tooltip from "@mui/material/Tooltip";
import {FormattedMessage} from "react-intl";

// Custom styles for ComplexProjectCard
function ComplexProjectCard({color, image, title, description, children, members, footer}) {
    const renderMembers = members.map((member, key) => {
        const memberKey = `member-${key}`;
        return (
            <Tooltip title={member.firstName}><MDAvatar
                key={memberKey}
                src={`https://www.gravatar.com/avatar/${ sha256( member.email||member.phone ) }?d=mp&s=250`}
                alt="member profile"
                size="xs"
                sx={({borders: {borderWidth}, palette: {white}}) => ({
                    border: `${borderWidth[2]} solid ${white.main}`,
                    cursor: "pointer",
                    position: "relative",

                    "&:not(:first-of-type)": {
                        ml: -1.25,
                    },

                    "&:hover, &:focus": {
                        zIndex: "10",
                    },
                })}
            /></Tooltip>
        );
    });

    const [isOverflowing, setIsOverflowing] = useState(false);
    const [showFullText, setShowFullText] = useState(false);
    const contentRef = useRef(null);

    // Function to check if content is overflowing
    const checkOverflow = () => {
        if(description) {
            //console.log(contentRef.current, contentRef.current.scrollHeight)
            if (contentRef.current) {
                setIsOverflowing(contentRef.current.scrollHeight > 100);
            }
        }
    };

    // Check overflow when component mounts or when text changes
    useEffect(() => {
        checkOverflow();
    }, [description]);


    return (
        <Card className={'pre-booking-card'}>
            <MDBox p={2}>
                <MDBox display="flex" alignItems="center">
                    <MDAvatar
                        src={image}
                        alt={title}
                        size="xxxl"
                        variant="rounded"
                        bgColor={color}
                        sx={{p: 1, mt: -10, borderRadius: ({borders: {borderRadius}}) => borderRadius.xl}}
                    />
                    <MDBox ml={2} mt={-2} lineHeight={0}>
                        <MDTypography variant="h5" fontWeight="medium" style={{color: 'rgba(116, 22, 180, 1)'}}>
                            {title}
                        </MDTypography>
                        {members.length > 0 ? <div className={'attendees-avatar-list pt-3 d-flex align-items-center'}><div className={'small'}><FormattedMessage id={'common.attendees.already'}/></div><MDBox display="flex">{renderMembers}</MDBox></div>: null}
                    </MDBox>
                </MDBox>
                <MDBox className={'pre-booking-card-body'} my={2} lineHeight={1} style={{position: 'relative'}}>
                    {children}
                    {description && <Paper
                        className={'comment-paper'}
                        ref={contentRef}
                        style={{
                          fontSize: '75%',
                          maxHeight: showFullText ? '35vh' : 100,
                          width: 'calc(100% - 48px)',
                          marginLeft: 24,
                          padding: 12,
                          marginTop: 6,
                          marginBottom: 24,
                          backgroundColor: 'rgba(116, 22, 180, 0.05)',
                          overflow: showFullText ? 'auto':'hidden',
                          position: 'relative',
                      }} elevation={3} dangerouslySetInnerHTML={{__html: description}}/>}
                  {description && isOverflowing && !showFullText && (
                      <div
                          style={{
                            position: 'absolute',
                            bottom: 0,
                            right: 24,
                            background: 'linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))',
                            paddingLeft: '10px',
                            width: 'calc(100% - 24px)',
                            textAlign: 'center',
                          }}
                      >
                        <MDButton
                            variant={'text'}
                            color={'secondary'}
                            style={{marginBottom: -35, minHeight: 20, height: 20, zIndex: 0,  borderTop: 'none',
                                backgroundColor: 'transparent',
                                borderRadius: 4,
                                transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                                boxShadow: '0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)'
                            }}
                           size={'small'}
                            onClick={() => setShowFullText(true)}
                        >
                            <FormattedMessage id={'common.readmore'}/>...
                        </MDButton>
                      </div>
                  )}
                </MDBox>
                <Divider className={'mb-2 mb-xl-3 mt-0 mt-lg-5'}/>
                {footer}
            </MDBox>
        </Card>
    );
}

// Setting default values for the props of ComplexProjectCard
ComplexProjectCard.defaultProps = {
    color: "dark",
    dateTime: "",
    members: [],
    dropdown: false,
};

// Typechecking props for the ProfileInfoCard
ComplexProjectCard.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
    ]),
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    dateTime: PropTypes.string,
    description: PropTypes.node.isRequired,
    members: PropTypes.arrayOf(PropTypes.string),
    dropdown: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            action: PropTypes.func,
            menu: PropTypes.node,
        }),
    ]),
};

export default ComplexProjectCard;
