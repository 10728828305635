/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/



// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import CheckedIcon from "@mui/icons-material/Check";
import UnCheckedIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";


function DefaultPricingCard({ color, badge, price, specifications, description, action, shadow, priceNew }) {

  const formatPrice = (value) => {
    let [num, dec] = value.toString().split(".");
    if(Number.isInteger(value)) {
      dec = '0';
    }
    dec = dec.padStart(2, "0")

    return <>{num}<sup style={{fontSize: '1.75rem', top: '-1.5rem'}}>{dec[0]+dec[1]}</sup></>
  }

  const renderSpecifications = specifications.map(({ label, includes }) => (
    <MDBox key={label} display="flex" alignItems="center" p={1}>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        mr={2}
        mt={-0.125}
      >
        <MDTypography
          variant="body1"
          color={color === "white" ? "text" : "white"}
          sx={{ lineHeight: 0 }}
        >
          {includes?<CheckedIcon color={'success'}/>:<UnCheckedIcon color={'error'}/>}
        </MDTypography>
      </MDBox>
      <MDTypography
        variant="body2"
        color={color === "white" ? "text" : "white"}
        fontWeight="regular"
      >
        {label}
      </MDTypography>
    </MDBox>
  ));

  return (
    <Card sx={{ border: ()=>(color === "white" ? "solid 0.5px gray" : "none"), boxShadow: ({ boxShadows: { lg } }) => (shadow ? lg : "none") }}>
      <MDBox
        bgColor={color}
        variant={color === "white" ? "contained" : "gradient"}
        borderRadius="xl"
      >
        <MDBox
          bgColor={badge.color}
          width="max-content"
          px={4}
          pt={0}
          pb={0.5}
          mx="auto"
          mt={-1.375}
          borderRadius="section"
          lineHeight={1}
        >
          <MDTypography
            variant="caption"
            textTransform="uppercase"
            fontWeight="medium"
            color={badge.color === "light" ? "dark" : "white"}
          >
            {badge.label}
          </MDTypography>
        </MDBox>
        <MDBox pt={3} pb={2} px={2} textAlign="center">
          <MDBox my={1}>
            <MDTypography variant="h1" color={color === "white" ? "dark" : "white"}>

              {priceNew ? <>
                   <small className={'text-danger'}>Old price: </small><strong  className={'text-danger strikethrough'}>
                {price.currency}
               {formatPrice(price.value)}</strong>
                <MDTypography display="inline" component="small" variant="h5" color="error">
                  /{price.type}
                </MDTypography>
                <br/>
                   <small className={'text-success'}>New price: </small><strong  className={'text-success'}>{price.currency}{formatPrice(priceNew.value)}</strong>
                <small className={'text-success'}>
                  /{price.type}
                </small></> :<>
                <MDTypography
                    display="inline"
                    component="small"
                    variant="h5"
                    color="inherit"
                    verticalAlign="top"
                >
                  {price.currency}
                </MDTypography>
              formatPrice(price.value)
                <MDTypography display="inline" component="small" variant="h5" color="inherit">
                  /{price.type}
                </MDTypography>
              </>}

            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox pb={3} px={3}>
          {renderSpecifications}
          {description && <div style={{textAlign: 'justify', minHeight: 150}}>
            <p>{description}</p>
          </div>}
          {action.type === "internal" ? (
            <MDBox mt={3}>
              <MDButton
                  component="a"
                  href={action.route}
                variant="gradient"
                color={action.color}
                fullWidth
              >
                {action.label}&nbsp;
                <ArrowForwardIcon/>
              </MDButton>
            </MDBox>
          ) : (
            <MDBox mt={3}>
              <MDButton
                component="a"
                href={action.route}
                variant="gradient"
                color={action.color}
                fullWidth
              >
                {action.label}&nbsp;
                <ArrowForwardIcon />
              </MDButton>
            </MDBox>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props for the DefaultPricingCard
DefaultPricingCard.defaultProps = {
  color: "white",
  shadow: true,
};

// Typechecking props for the DefaultPricingCard
DefaultPricingCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "white",
  ]),
  badge: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  price: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  specifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
  }).isRequired,
  shadow: PropTypes.bool,
};

export default DefaultPricingCard;
