/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import _ from "lodash";
import moment from "moment";
// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Swal2 from "sweetalert2";

// Material Dashboard 2 PRO React components
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDDatePicker from "components/MDDatePicker";
import MDTypography from "components/MDTypography";

// Settings page components
import FormField from "views/examples/account/components/FormField";

import MDEditor from "components/MDEditor";

// Data
import selectData from "views/examples/account/settings/components/BasicInfo/data/selectData";
import React, {useCallback} from "react";
import {usePlacesWidget} from "react-google-autocomplete";
import {API} from "api";
import {FormattedMessage, useIntl} from "react-intl";


const api = new API()

function BasicInfo({form, setFormField, fetchUser}) {

    const intl = useIntl()
    const {ref} = usePlacesWidget({
        apiKey: 'AIzaSyCcb4EBiv3DHMlzvVF_mcLaoN5JuGV4bS4',
        options: {
            types: ['address'],
            componentRestrictions: {country: "lt"},
        },
        onPlaceSelected: (place) => setFormField('location', place.formatted_address)
    })


    const handleSave = useCallback(async () => {
        await api.post(`users/${form.uuid}`, _.pick(form, ['summary', 'description']))
        await fetchUser()
        Swal2.fire({title: intl.formatMessage({id: 'common.alert.saved'}), html: intl.formatMessage({id: 'common.profile.updated'}), icon: 'success',confirmButtonText: intl.formatMessage({id: 'common.confirm'})})
    }, [form]);

    return (
        <Card id="basic-info" sx={{overflow: "visible"}}>
            <MDBox p={3}>
                <MDTypography variant="h5"><FormattedMessage id={"forms.description"}/></MDTypography>
            </MDBox>
            <MDBox component="form" pb={3} px={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} className={'mb-3'}>
                        <FormField label={intl.formatMessage({id: "forms.description.summary"})} multiline max={500} rows={8} placeholder=""
                                   inputProps={{style: {border:'solid 1px silver', padding: 4}, max: 500, maxLength: 500, value: form.summary, onChange: v => setFormField('summary', v)}}/>
                    </Grid>
                    {/*<hr style={{height: 3, width: 'calc(100% + 48px)', marginRight: -24}}/>
                    <Grid item xs={12}>
                        <FormField label={intl.formatMessage({id: "forms.description.description"})}  multiline rows={12} placeholder="Start typing..."
                                   inputProps={{value: new DOMParser().parseFromString(form.description , 'text/html').body.textContent, onChange: v => setFormField('description', v)}}/>
                    </Grid>*/}
                    <MDBox ml="auto" mt={3}>
                        <MDButton variant="gradient" color="info" size="small" onClick={handleSave}>
                            <FormattedMessage id={"forms.description.save"}/>
                        </MDButton>
                    </MDBox>
                </Grid>
            </MDBox>
        </Card>
    );
}

export default BasicInfo;
