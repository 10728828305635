/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, {useCallback, useMemo, useState} from "react";
import _ from "lodash";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDDropzone from "components/MDDropzone";
import MDButton from "components/MDButton";


import sha256 from 'js-sha256'
import {FormattedMessage, useIntl} from "react-intl";

import Cropper from 'cropperjs'
import Swal from 'sweetalert2'
import { throttle } from '@github/mini-throttle'
import 'cropperjs/dist/cropper.css'
import {API} from "../../../../../../api";


const api = new API()
let blob = null;

function Header({form, setFormField, handleSave, updateUser}) {
    const [opened, setOpened] = useState(false);
    const avatar = useMemo(() => {
        return form.avatar || `https://www.gravatar.com/avatar/${ sha256( form.email||form.phone ) }?d=mp&s=250`
    }, [form])
    const intl = useIntl();

    return (
        <Card id="profile">
            <MDBox p={2}>
                <Grid container spacing={3}>
                    <Grid item>
                        <MDAvatar src={avatar} alt="profile-image" size="x-250" shadow="sm"/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDBox height="100%" mt={0.5} lineHeight={1} width={'100%'}>
                            <MDTypography variant="h5" fontWeight="medium">
                                {form.fullname}
                            </MDTypography>
                            {!opened &&
                                <Grid item xs={12}><MDButton onClick={() => setOpened(o => !o)} variant={'contained'}
                                                             color={'success'}><FormattedMessage id={"forms.avatar"}/></MDButton></Grid>}

                            {opened && <MDDropzone options={{
                                dictDefaultMessage: intl.formatMessage({id: 'common.drop.avatar'}),
                                maxFilesize: 10,
                                createImageThumbnails: false,
                                acceptedFiles: 'image/*',
                                disablePreviews: true,
                                autoDiscover: false,
                                withCredentials: true,
                                url: `${api.baseURL}/users/avatar`,
                                autoProcessQueue: true,
                                addedfile: (file) => {
                                    console.log({...file})
                                },
                                success: (file) => {


                                    Swal.fire({
                                        allowOutsideClick: false,
                                        showCancelButton: true,
                                        title: 'Crop Your Avatar',
                                        html: `
<div class="MuiAvatar-root MuiAvatar-circular css-go5r18" style="margin: auto; margin-bottom: 10px;">
<img alt="profile-image" id="preview" src="${JSON.parse(file.xhr.response).url}" class="MuiAvatar-img css-u226zh">
</div>
    <div >
      <img id="cropperjs" src="${JSON.parse(file.xhr.response).url}">
    </div>
  `,
                                        willOpen: () => {
                                            const image = Swal.getPopup().querySelector('#cropperjs')
                                            const cropper = new Cropper(image, {
                                                aspectRatio: 1,
                                                viewMode: 1,
                                                zoomOnWheel: false,
                                                minCropBoxWidth: 250,
                                                minCropBoxHeight: 250,
                                                crop: throttle(function () {
                                                    const croppedCanvas = cropper.getCroppedCanvas({width: 250})
                                                    const preview = Swal.getPopup().querySelector('#preview')
                                                    preview.src = croppedCanvas.toDataURL()
                                                    croppedCanvas.toBlob((b)=>blob=b);
                                                }, 10),
                                            })
                                        },
                                        preConfirm: async () => {
                                            const file = new File([blob], 'avatar.png', { type: 'image/png' });
                                            const res = await api.upload('users/avatar', file);
                                            setFormField('avatar', res.url);

                                            console.log(handleSave)
                                            if(handleSave) {
                                                await handleSave(['avatar']);
                                            }
                                            if(updateUser) {
                                                await updateUser(['avatar'], {avatar: res.url})
                                            }
                                            return true;
                                        },
                                    })

                                    //setFormField('avatar', JSON.parse(file.xhr.response).url)
                                }
                            }}/>}
                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox>
        </Card>
    );
}

export default Header;
