/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React, {useCallback, useMemo} from "react";
// @mui material components
import Grid from "@mui/material/Grid";


// Settings page components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import Header from "views/examples/account/settings/components/Header";
import BasicInfo from "views/examples/account/settings/components/BasicInfo";
import {
    Container,
} from "reactstrap";

import {useAuth} from "useAuth";
import _ from "lodash";
import {API} from "../../../api";


const api = new API()

function Settings() {
    const {user, fetchUser} = useAuth();

    const [form, setForm] = React.useState(_.cloneDeep(user));

    const setFormField = (name, value) => {
        console.log(name,value)
        if(name.indexOf(".")===-1) {
            setForm(f => ({...f, [name]: value?.target ? value.target.value : value}))
        } else {
            setForm(_.set(form, name, value))
        }
    }
    const handleSave = useCallback(async (fields=['programURL', 'foodUrl','languages','name', 'contactEmail', 'surname', 'gender', 'avatar', 'phone', 'location', 'locationData', 'birthDate', 'coachData.instagramURL', 'coachData.fbURL', 'coachData.foodURL', 'coachData.programURL']) => {
        await api.post(`users/${form.uuid}`, _.pick(form, fields))
        await fetchUser()
        //Swal2.fire({title: intl.formatMessage({id: 'common.alert.saved'}), html: intl.formatMessage({id: 'common.profile.updated'}), icon: 'success',confirmButtonText: intl.formatMessage({id: 'common.confirm'})})
    }, [form]);


    return (
        <>
            <ExamplesNavbar/>
            <Container className={'mt-navbar mb-footer'}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Header form={form} setFormField={setFormField} handleSave={handleSave}/>
                    </Grid>
                    <Grid item xs={12}>
                        <BasicInfo form={form} setFormField={setFormField} fetchUser={fetchUser} />
                    </Grid>
                </Grid>
            </Container>
            <DemoFooter/>
        </>
    );
}

export default Settings;
