/*!

=========================================================
* Paper Kit React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
// reactstrap components
import {
    Label,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import HeaderMap from "components/Headers/HeaderMap.js";
import Slider from "nouislider";
import "nouislider/dist/nouislider.min.css";
import MasterCardListItem from "views/examples/MasterCardListItem";


import Card from "@mui/material/Card";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Save';
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import PulseLoader from "react-spinners/PulseLoader";

import {Scrollbar} from 'react-scrollbars-custom';
import {API, citiesArray, sleep} from "api";
import _ from "lodash";
import {useAuth} from "useAuth";
import MDBadge from "components/MDBadge";
import MDInput from "components/MDInput";
import Select from "react-select";
import SelectCreatable from "react-select/creatable";
import {FormattedMessage, useIntl} from "react-intl";
import StarIcon from "@mui/icons-material/Star";
import {Rating} from "react-simple-star-rating";
import selectData from "./account/settings/components/BasicInfo/data/selectData";
import {capitalizeFirstLetter} from "./account/settings/components/BasicInfo";
import Collapse from "@mui/material/Collapse";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import {useLocale} from "../../useLocale";


const api = new API()

function SearchPage() {

    const intl = useIntl()
    const {user, dicts} = useAuth();
    const {locale} = useLocale();
    const [loading, setLoading] = React.useState(true);
    const [filterExpanded, setFilterExpanded] = React.useState(false);
    const [priceSlider, setPriceSlider] = React.useState({
        priceMax: 200,
        priceMin: 0
    });
    const [filter, setFilter] = React.useState({
        minPrice: 10,
        maxPrice: 100,
        city: 'Vilnius'
    });
    const [selected, setSelectedState] = React.useState(null);
    const [uniqueActivities, setUniqueActivities] = React.useState([]);
    const [opacity, setOpacity] = React.useState(0);
    const mapRef = useRef();
    const scrollRef = useRef();
    const navigate = useNavigate();

    React.useEffect(() => {
        api.get('sessions/metadata').then((metadata = {
            priceMax: 200,
            priceMin: 0
        }) => {
            if (metadata.priceMin === metadata.priceMax) {
                metadata.priceMin = 0;
            }
            if (metadata.priceMax) {
                setFilterField('minPrice', metadata.priceMin)
                setFilterField('maxPrice', metadata.priceMax)
                setPriceSlider(metadata)
            }
        })
        if (user) {
            setUniqueActivities(user.dicts?.sports.map(s => ({name: s.name[intl.locale], id: s.key})))
        } else {
            api.get('dict/sports').then(sports => setUniqueActivities(sports?.map(s => ({
                name: s.name[intl.locale],
                id: s.key
            })) || []))
        }
    }, [intl.locale]);

    const setFilterField = (field, value) => {
        setFilter(f => ({...f, [field]: value}))
    }

    const [points, setPoints] = React.useState([]);

    const getPointsCall = React.useCallback(
        _.debounce(async (lat, lng, filter) => {
            if (mapRef.current) {
                setLoading(true)
                const pRes = await api.post(`schedules/search`, {
                    lat, lng, bounds: {
                        southwest: mapRef.current.getBounds({asGeoJSON: true}).getSouthWest(),
                        northeast: mapRef.current.getBounds({asGeoJSON: true}).getNorthEast(),
                    }, filter
                });
                if (!pRes) {
                    setTimeout(() => getPoints(lat, lng), 500)
                } else {
                    setPoints([])
                    setPoints(pRes.map(p => ({...p, geometry: p.locationData.geometry, rating: p.rating})) || [])
                    setLoading(false)
                }
            }
        }, 1000),
        []
    );

    const getPoints = React.useCallback(
        async (lat, lng) => getPointsCall(lat, lng, filter),
        [filter, getPointsCall])

    document.documentElement.classList.remove("nav-open");
    const setSelected = (v) => {
        setSelectedState(v);
    }
    const refCbk = React.useCallback(async (filter) => {
        if (mapRef.current) {
            const loc = mapRef.current.center
            if (loc.lat())
                await getPoints(loc.lat(), loc.lng())
        }
    }, [mapRef, getPoints]);
    const handleClickBook = React.useCallback((coachId) => {
        const path = true ? `/coach-profile/${coachId}` : `/client/signup?from=/coach-profile/${coachId}/grafikas`;
        navigate(path)
    }, [user]);
    React.useEffect(() => {
        refCbk(filter)
    }, [filter, refCbk]);

    React.useEffect(() => {
        if (
            !document.getElementById("sliderDouble").classList.contains("noUi-target")
        ) {
            Slider.create(document.getElementById("sliderDouble"), {
                start: [filter.minPrice, filter.maxPrice],
                connect: true,
                step: 1,

                range: {min: priceSlider.priceMin, max: priceSlider.priceMax},
                tooltips: [
                    {to: v => '€️' + v.toFixed()},
                    {to: v => '€️' + v.toFixed()}
                ],
            }).on('end', (values) => {
                console.log(values, filter, parseInt(values[0]))
                setFilterField('minPrice', parseInt(values[0]))
                setFilterField('maxPrice', parseInt(values[1]))
            });
        } else if (document.getElementById("sliderDouble") && document.getElementById("sliderDouble").noUiSlider) {
            document.getElementById("sliderDouble").noUiSlider.updateOptions({
                start: [filter.minPrice, filter.maxPrice],
                range: {
                    min: priceSlider.priceMin,
                    max: priceSlider.priceMax,
                }
            })
            document.getElementById("sliderDouble").noUiSlider.set([filter.minPrice, filter.maxPrice])
        }
    }, [setFilterField, priceSlider, filter]);

    const [inUse, setInUse] = useState();

    const [filterOpen, setFilterOpen] = useState(false)

    const scrollToSelected = (index) => {
        const sb = document.querySelector(`.ScrollbarsCustom-Wrapper`);
        const el = document.getElementById(`place-${index}`);
        const filterEl = document.querySelector(`.filter-card`);
        if (sb && el) {
            const bounds = el.getBoundingClientRect();
            if (window.screen.width < 700) {
                console.log({
                    'window.screen.width < 700': window.screen.width < 700,
                    filterOpen,
                    'window.screen.width': window.screen.width,
                    'sb.offsetTop': sb.offsetTop,
                    'sb.getBoundingClientRect()': sb.getBoundingClientRect(),
                    'el.offsetTop': el.offsetTop,
                    'filterEl.getBoundingClientRect()': filterEl.getBoundingClientRect(),
                    '(filterOpen ? (sb.offsetTop + filterEl.getBoundingClientRect().height) : sb.offsetTop)': (filterOpen ? (sb.offsetTop + filterEl.getBoundingClientRect().height) : sb.offsetTop)
                })
                window.scrollTo(0, filterOpen ? (sb.offsetTop + filterEl.getBoundingClientRect().height) : sb.offsetTop);
                scrollRef.current.scrollTo(0, el.offsetTop)
            }
            if (window.screen.width >= 700) {
                console.log({
                    'window.screen.width >= 700': window.screen.width >= 700,
                    filterOpen,
                    'window.screen.width': window.screen.width,
                    'sb.offsetTop': sb.offsetTop,
                    'sb.getBoundingClientRect()': sb.getBoundingClientRect(),
                    'el.offsetTop': el.offsetTop,
                    'filterEl.getBoundingClientRect()': filterEl.getBoundingClientRect(),
                    '(filterOpen ? (sb.offsetTop + filterEl.getBoundingClientRect().height) : sb.offsetTop)': (filterOpen ? (sb.offsetTop + filterEl.getBoundingClientRect().height) : sb.offsetTop) + 300 + filterEl.getBoundingClientRect().top
                })
                scrollRef.current.scrollTo(0, el.offsetTop + sb.offsetTop - 0.33 * el.getBoundingClientRect().height)
                window.scrollTo(0, el.offsetTop + sb.offsetTop - 0.33 * el.getBoundingClientRect().height);
            }
        }
    }


    return (
        <>
            <ExamplesNavbar/>
            <HeaderMap user={user} filter={filter} mapRef={mapRef} events={points} refCbk={refCbk}
                       setSelected={setSelected} scrollToSelected={scrollToSelected}/>
            <Container fluid={'xl'} className={'container-search'}>
                <Row>
                    <Col lg={3} md={4} className={'pt-3 pt-lg-0'}>
                        <Card className=" mt-md-n5 ml-lg-1 ml-xl-5 filter-card" style={{padding: 8}}>
                            <MDButton id={'filter-button'} variant={'gradient'} color={'violet'}
                                      className={`d-md-none p-0`} onClick={() => setFilterOpen(f => !f)}>
                                <FilterAltIcon className={'mr-1'}/>
                                <MDTypography variant={'h6'} color={'white'}><FormattedMessage
                                    id={"search.filter"}/></MDTypography>
                            </MDButton>
                            <h6 className=" mx-auto mt-n2 d-none d-md-block"><FormattedMessage id={"search.filter"}/>
                            </h6>

                            <div className={filterOpen ? 'd-md-block' : `d-none d-md-block`}>
                                <div className="title">
                                    <strong><FormattedMessage id={"search.sport"}/></strong>
                                </div>
                                <div style={{zoom: 0.75}}>
                                    <Select
                                        placeholder={intl.formatMessage({id: 'common.any'})}
                                        styles={{menu: (baseStyles, state) => ({...baseStyles, zIndex: 9999999999})}}
                                        options={[{
                                            value: null,
                                            label: `---${intl.formatMessage({id: 'common.forms.any'})}---`
                                        }, ...uniqueActivities.map(a => ({label: a.name, value: a.id}))]}

                                        onChange={(v) => {
                                            setFilter(f => ({
                                                ...f,
                                                activity: v?.value || null
                                            }))
                                        }}/>
                                </div>
                                <div className="title">
                                    <strong><FormattedMessage id={"search.city"} defaultMessage={'City'}/></strong>
                                </div>
                                <div style={{zoom: 0.75}}>
                                    <SelectCreatable
                                        placeholder={intl.formatMessage({id: 'common.any'})}
                                        styles={{menu: (baseStyles, state) => ({...baseStyles, zIndex: 9999999999})}}
                                        options={citiesArray}
                                        formatCreateLabel={(l) => `Search in "${l}"...`}
                                        value={{
                                            value: filter.city,
                                            label: filter.city
                                        }}
                                        onCreateOption={async (inputValue) => {
                                            const res = await fetch(`https://nominatim.openstreetmap.org/search?city=${inputValue}&country=Lithuania&format=json`).then(r => r.json()).catch(e => console.log)
                                            if (res && res.length > 0) {
                                                citiesArray.push({
                                                    "label": res[0].name,
                                                    "value": res[0].name,
                                                    "coordinates": {
                                                        "lat": res[0].lat * 1,
                                                        "lng": res[0].lon * 1
                                                    },
                                                    "zoom": 14
                                                })
                                                if (mapRef.current) {
                                                    const curCity = citiesArray.find(({value}) => value === res[0].name);
                                                    console.log(curCity)
                                                    mapRef.current.setCenter(curCity.coordinates)
                                                    mapRef.current.setZoom(curCity.zoom - 1)
                                                }
                                                await sleep(250)
                                                setFilter(f => ({
                                                    ...f,
                                                    city: res[0].name
                                                }))
                                            }
                                        }}

                                        onChange={async (v) => {
                                            if (mapRef.current) {
                                                const curCity = citiesArray.find(({value}) => value === v.value);
                                                console.log(curCity)
                                                mapRef.current.setCenter(curCity.coordinates)
                                                mapRef.current.setZoom(curCity.zoom - 3)
                                            }
                                            await sleep(250)
                                            setFilter(f => ({
                                                ...f,
                                                city: v?.value || null
                                            }))
                                        }}/>
                                </div>


                                <div className="title pb-4">
                                    <strong><FormattedMessage id={"search.priceRange"}/></strong>
                                </div>
                                <div style={{zoom: 0.75}} className={'p-3'}>
                                    <div className="slider slider-warning" id="sliderDouble"/>
                                </div>

                                <Collapse in={filterExpanded}>
                                    <div className="title">
                                        <strong><FormattedMessage id={"search.language"}/></strong>
                                    </div>
                                    <div style={{zoom: 0.75}}>
                                        <Select
                                            placeholder={intl.formatMessage({id: 'common.any'})}
                                            styles={{
                                                menu: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    zIndex: 9999999999
                                                })
                                            }}
                                            options={[{
                                                value: null,
                                                label: `---${intl.formatMessage({id: 'common.forms.any'})}---`
                                            }, ...selectData.languages.map(a => ({
                                                label: capitalizeFirstLetter(intl.formatDisplayName(selectData.languageCodes.find(c => c.name === a).code, {type: 'language'})),
                                                value: a
                                            }))]}

                                            onChange={(v) => {
                                                setFilter(f => ({
                                                    ...f,
                                                    language: v?.value || null
                                                }))
                                            }}/>

                                    </div>
                                    <div className="title">
                                        <strong><FormattedMessage id={"forms.special.type"}/></strong>
                                    </div>
                                    <FormGroup check>
                                        <Label check className={'label-small'}>
                                            <Input
                                                value={filter.individual}
                                                onChange={(e) => setFilterField('individual', e.target.checked)}
                                                type="checkbox"/>
                                            <i className="nc-icon nc-check-2"/>
                                            <FormattedMessage id={"forms.special.type.individual"}/> <span
                                            className="form-check-sign"/>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label check className={'label-small'}>
                                            <Input
                                                value={filter.group}
                                                onChange={(e) => setFilterField('group', e.target.checked)}
                                                type="checkbox"/>
                                            <i className="nc-icon nc-check-2"/>
                                            <FormattedMessage id={"forms.special.type.group"}/> <span
                                            className="form-check-sign"/>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label check className={'label-small'}>
                                            <Input
                                                value={filter.special}
                                                onChange={(e) => setFilterField('special', e.target.checked)}
                                                type="checkbox"/>
                                            <i className="nc-icon nc-check-2"/>
                                            <FormattedMessage id={"forms.special.type.special"}/> <span
                                            className="form-check-sign"/>
                                        </Label>
                                    </FormGroup>

                                    <div className="title">
                                        <strong><FormattedMessage id={"forms.special.benefits"}/> </strong>
                                    </div>
                                    <FormGroup check>
                                        <Label check className={'label-small'}>
                                            <Input
                                                value={filter.food}
                                                onChange={(e) => setFilterField('food', e.target.checked)}
                                                type="checkbox"/>
                                            <i className="nc-icon nc-check-2"/>
                                            <FormattedMessage id={"forms.special.benefits.food"}/> <span
                                            className="form-check-sign"/>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label check className={'label-small'}>
                                            <Input
                                                value={filter.sports}
                                                onChange={(e) => setFilterField('sports', e.target.checked)}
                                                type="checkbox"/>
                                            <i className="nc-icon nc-check-2"/>
                                            <FormattedMessage id={"forms.special.benefits.programs"}/> <span
                                            className="form-check-sign"/>
                                        </Label>
                                    </FormGroup>

                                    <div className="title">
                                        <strong><FormattedMessage id={"search.rating"}/></strong>
                                    </div>
                                    <FormGroup>
                                        <FormGroup check>
                                            <Label check className={'label-small'}>
                                                <Input
                                                    value={filter.onlyWithReviews}
                                                    onChange={(e) => setFilterField('onlyWithReviews', e.target.checked)}
                                                    type="checkbox"/>
                                                <i className="nc-icon nc-check-2"/>
                                                <FormattedMessage id={"search.onlyWithReviews"}/> <span
                                                className="form-check-sign"/>
                                            </Label>
                                        </FormGroup>
                                        <div className="title">
                                            <strong><FormattedMessage id={"search.rating.min"}/></strong>
                                        </div>
                                        <Rating
                                            onClick={v => setFilterField('minRating', v)}
                                        /> {filter.minRating ?
                                        <strong className={'text-success'}>{filter.minRating}+</strong> : null}
                                    </FormGroup>

                                    <div className="title">
                                        <strong><FormattedMessage id={"search.other"}/></strong>
                                    </div>
                                    <FormGroup className={'label-small'}>
                                        <MDInput type="text"
                                                 className={'input-filter-name'}
                                                 label={`${intl.formatMessage({id: "common.name"})}, ${intl.formatMessage({id: "common.surname"})}`}
                                                 name={'name'}
                                                 fullWidth
                                                 value={filter.name}
                                                 onChange={e => setFilterField('name', e.target.value)}/>
                                    </FormGroup>
                                </Collapse>
                                <div className={'p-0 mt-1 text-center pt-1'}>&nbsp;
                                </div>
                                <div className={'p-0 mb-n3 text-center pt-1'}>
                                    <MDButton variant={'link'} onClick={() => setFilterExpanded(v => !v)}>
                                        {!filterExpanded ? <KeyboardDoubleArrowDownIcon
                                            fontSize={'large'}/> : <KeyboardDoubleArrowUpIcon
                                            fontSize={'large'}/>}
                                    </MDButton>
                                </div>
                            </div>
                            {filterOpen &&
                                <MDButton variant={'gradient'} color={'violet'} className={`mt-n3 mb-4 d-md-none p-0`}
                                          onClick={() => {
                                              window.scrollTo({top: 0, behavior: 'smooth'});
                                              setFilterOpen(false)
                                          }}>
                                    <CloseIcon className={'mr-1'}/>
                                    <MDTypography variant={'h6'} color={'white'}>Save Filter</MDTypography>
                                </MDButton>}
                        </Card>
                    </Col>

                    <Col lg={9} md="8" className={'p-lg-0 p-xl-2 mt-3 mt-md-0'}>

                        <Scrollbar
                            className={'scrollbar-items'}
                            ref={scrollRef}
                            noScrollX={true}
                            trackYProps={{style: {opacity}}}
                            onScrollStart={(() => {
                                setInUse(true)
                                setOpacity(1)
                            })}
                            onScrollStop={(() => {
                                for (let i = 0; i <= 20; i++) {
                                    setTimeout(() => {
                                        if (inUse) {
                                            setOpacity(o => o - 0.05)
                                        }
                                    }, 10 * i);
                                }
                                setInUse(false)
                            })}
                            onScroll={(scrollValues) => {
                                if (scrollValues.scrollTop < 1) {
                                    window.scrollBy(0, -100)
                                }
                            }} style={{height: '200vh', paddingRight: 5}}>
                            {loading && <Container fluid={true} className={'p-3 text-center min-vh-100'}><PulseLoader
                                color={'#9180ff'} size={18}/></Container>}
                            {!loading && <Row className={'pr-0 '} style={{paddingTop: 24}}>{points.length < 1 &&
                                <Container fluid={true} className={'p-5 text-center'}>Nothing found, try adjusting
                                    filter or zoom out the map.</Container>}
                                {points.map(p => ({
                                    ...p,
                                    uuid: p.uuid,
                                    place_id: p.uuid,
                                    rating: p.rating,
                                    name: p.name,
                                    description: p.description,
                                    geometry: p.geometry,
                                    photo: p.photos ? `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=${p.photos?.[0]?.photo_reference}&key=AIzaSyCcb4EBiv3DHMlzvVF_mcLaoN5JuGV4bS4` : null
                                }))
                                .map((p, index) => (
                                    <div className="mb-4 mt-2 col-lg-6  col-12">
                                        <div className="card m-auto">
                                            <div className="px-4 py-3">
                                                <div className="d-flex">
                                                    <div
                                                        className="v-avatar border-radius-xl bg-gradient-default pa-2 mt-n6"
                                                        style={{ height: '74px', minWidth: '74px', width: '74px' }}
                                                    >
                                                        <div
                                                            className="v-image v-responsive theme--light"
                                                            style={{ height: '50px', width: '50px' }}
                                                        >
                                                            <div
                                                                className="v-responsive__sizer"
                                                                style={{ paddingBottom: '84.7458%' }}
                                                            />
                                                            <div
                                                                className="v-image__image v-image__image--cover"
                                                                style={{
                                                                    backgroundImage:
                                                                        'url('+p.avatar+')',
                                                                    backgroundPosition: 'center center',
                                                                }}
                                                            />
                                                            <div
                                                                className="v-responsive__content"
                                                                style={{ width: '177px' }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="ms-4 my-auto">
                                                        <h6 className="ml-1 text-h6 text-typo font-weight-bold mb-0">{p.name}</h6>

                                                        <div className="avatar-group d-flex ">
                                                            <div className={'one-line'} style={{height: 28}}>
                                                                {p.coachData?.activities?.map(act=>(<MDBadge sx={{'>span':{marginLeft: '3px !important'}}} key={act.name} badgeContent={dicts.sports.find(s=>s.key===act.id).name[locale]} />))}</div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <MDBox className={'p-1'} >
                                                    <MDTypography variant={'caption'} className={'pt-2 card-desc'}>
                                                        {p.description}
                                                    </MDTypography>
                                                </MDBox>

                                                <hr className="horizontal dark mb-3" />

                                                <div className="row">
                                                    <div className="col-6">
                                                        <MDBox sx={{left: 10}} textAlign={'left'}>
                                                            <MDBadge className={'badge-card-marker'} color={'violet'} badgeContent={
                                                                <MDTypography variant="h6" color="white" fontWeight="medium">
                                                                    {(p.maxPrice && p.maxPrice > p.minPrice) ? (<><FormattedMessage
                                                                        id={'common.from'}/> &euro;{p.minPrice} <FormattedMessage
                                                                        id={'common.to'}/> &euro;{p.maxPrice}</>) : (<>&euro;{p.minPrice}</>)}
                                                                </MDTypography>
                                                            } container/>
                                                        </MDBox>
                                                        <p className="text-secondary text-sm font-weight-normal mb-0"></p>
                                                    </div>

                                                    <div className="col-6 text-end d-flex flex-wrap justify-content-end gap-2">
                                                        <div className={'one-line'} style={{height: 28}}>
                                                            {p.coachData?.locations?.map(act => (
                                                                <MDBadge sx={{'>span':{marginLeft: '3px !important'}}} key={act.name} color={'dark'} badgeContent={act.name}/>))}</div>
                                                    </div>
                                                </div>

                                                <div className="row mt-3">
                                                    <div className="col-12">
                                                        <MDButton fullWidth variant={'gradient'} onClick={()=>handleClickBook(p.uuid)} className="mx-auto" color="success">
                                                            {user?<FormattedMessage id={"search.signup.logged"}/>:<FormattedMessage id={"search.signup"} />}
                                                        </MDButton>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    ))}

                                {[].map(p => ({
                                    ...p,
                                    uuid: p.uuid,
                                    place_id: p.uuid,
                                    rating: p.rating,
                                    name: p.name,
                                    description: p.description,
                                    geometry: p.geometry,
                                    photo: p.photos ? `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=${p.photos?.[0]?.photo_reference}&key=AIzaSyCcb4EBiv3DHMlzvVF_mcLaoN5JuGV4bS4` : null
                                }))
                                    .map((p, index) => (
                                        <Col lg={'6'} sm={'12'} className={'p-3 master-card-wrapper'} key={p.uuid}
                                             id={'place-' + p.uuid}>
                                            <MasterCardListItem
                                                locale={locale}
                                                isTop={index < 2}
                                                image={p.avatar}
                                                title={p.name}
                                                uuid={p.uuid}
                                                scrollToSelected={scrollToSelected}
                                                handleClickBook={handleClickBook}
                                                pricing={(p.maxPrice && p.maxPrice > p.minPrice) ? (<><FormattedMessage
                                                    id={'common.from'}/> &euro;{p.minPrice} <FormattedMessage
                                                    id={'common.to'}/> &euro;{p.maxPrice}</>) : (<>&euro;{p.minPrice}</>)}
                                                rating={p.rating}
                                                coachData={p.coachData}
                                                dicts={dicts}
                                                description={(<MDBox>
                                                    <MDBox className={'only-selected '}>
                                                        <MDBadge color={'success'}
                                                                 className={'badge-card-marker mt-1 mb-2'}
                                                                 badgeContent={
                                                                     <MDBox width={'100%'} display="flex"
                                                                            justifyContent="space-between"
                                                                            alignItems="space-between">
                                                                         <MDTypography variant="h6"
                                                                                       className={'h6-bottom'}
                                                                                       color="white"
                                                                                       fontWeight="medium">
                                                                             <StarIcon color={'gold'}
                                                                                       style={{marginTop: -4}}
                                                                                       fontSize={'small'}/> {p.rating ? parseFloat(p.rating).toFixed(1) : intl.formatMessage({id: "common.noreviews"})}
                                                                         </MDTypography>
                                                                     </MDBox>
                                                                 } container/>
                                                    </MDBox>
                                                    <MDTypography variant={'caption'}>{p.summary}</MDTypography>
                                                </MDBox>)}
                                                action={null}
                                                isLoggedIn={true}
                                                selected={selected === p.uuid}
                                                setSelected={setSelected}
                                            />
                                        </Col>
                                    ))}
                            </Row>}
                            <div style={{height: 100}}>&nbsp;</div>
                            {points.length < 1 ? (<Row className={'pr-1'}>
                                <Col lg={'6'} sm={'12'} style={{height: 500}} key={'place-last'} id={'place-last'}/>
                            </Row>) : ''}
                        </Scrollbar>

                    </Col>
                </Row>
            </Container>


            <DemoFooter/>
        </>
    );
}

export default SearchPage;
