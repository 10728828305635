/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DefaultPricingCard from "views/examples/Cards/PricingCards/DefaultPricingCard";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

import round from "lodash/round";

function PricingCards({ products }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return products?.length?(
      <>
         <MDBox position="relative" zIndex={10} mt={8} px={{ xs: 1, sm: 0 }}>
      <Grid container spacing={3} justifyContent="center">
          {products.map((product)=>(<Grid item xs={12} lg={4}>
          <DefaultPricingCard
            color={darkMode ? "dark" : "white"}
            badge={{ color: darkMode ? "warning" : "light", label: product.name}}
            price={{ currency: "€", value: product.price.unit_amount/100, type: product.metadata.id==='monthly'?"mėn":"met" }}
            priceNew={{ value: round(product.price.unit_amount/100, 2) - round(((product.price.unit_amount/100) * ((product.coupon.percent_off)/100)), 2)}}
            description={product.description}
            specifications={[
             // { label: "Standartinė paskyra", includes: true },
             // { label: "Būkite labiausiai pastebimas savo srities treneriu 1 mėn", includes: false },
             // { label: "Online treniruočių vedimas", includes: false },
             // { label: "Reklamuokite savo sporto programas/mitybos planus ar kitas norimas paslaugas*", includes: false },
            ]}
            action={{
              type: "internal",
              route: "/business/checkout/"+product.metadata.id,
              color: darkMode ? "warning" : "dark",
              label: "select",
            }}
            shadow={darkMode}
          />
        </Grid>))}
      </Grid>
    </MDBox>
      </>
  ):null;
}

export default PricingCards;
