/*!

=========================================================
* Paper Kit React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useCallback, useMemo} from "react";
import {Link, useLocation} from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";

// reactstrap components
import
{
    Collapse,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container,
} from "reactstrap";


//icons
import SearchIcon from '@mui/icons-material/Search';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import ScheduleIcon from '@mui/icons-material/Schedule';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';


import NotificationItem from '../../views/examples/NotificationItem';
import IconButton from "@mui/material/IconButton";
import Drawer from "@mui/material/Drawer";
import Menu from "@mui/material/Menu";

import {useAuth} from "useAuth";
import {useState} from "react";


import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

import {
    navbarIconButton, navbarIconButton2,
} from "./styles";
import {FormattedMessage, useIntl} from "react-intl";
import {useLocale} from "../../useLocale";
import Select from "react-select";
import Avatar from "@mui/material/Avatar";
import {blue} from "@mui/material/colors";
import ListItemAvatar from "@mui/material/ListItemAvatar";

const topNavItems = [
    {name: 'about', reg: /\/about/i},
    {name: 'search', reg: /\/search/i},
    {name: 'coaches', reg: /\/coaches/i},
]

const localeOptions = [
    {label: 'EN', value: 'en'},
    {label: 'LT', value: 'lt'},
]

function ExamplesNavbar() {
    const location = useLocation();
    const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
    const [navbarCollapse, setNavbarCollapse] = React.useState(false);
    const intl = useIntl()
    const {user, signout} = useAuth()
    const {locale, setLocale} = useLocale()
    const [openMenu, setOpenMenu] = useState(false);
    const [openLoginMenu, setOpenLoginMenu] = useState(false);
    const [bookingsCount, setBookingsCount] = useState(user.bookingsCount);

    const handleOpenLoginMenu = (event) => setOpenLoginMenu((openLoginMenu)=>openLoginMenu?false:event.currentTarget);
    const handleOpenMenu = (event) => {
        setOpenMenu(event.currentTarget);
    }
    const handleCloseMenu = () => setOpenMenu(false);
    const handleCloseLoginMenu = () => setOpenLoginMenu(false);
    const toggleNavbarCollapse = () => {
        setOpenLoginMenu(false);
        setNavbarCollapse(!navbarCollapse);
        document.documentElement.classList.toggle("nav-open");
        document.querySelector('.navbar-collapse .navbar-nav button')?.classList[navbarCollapse?'add':'remove']('d-none')
    };

    const currentPage = useMemo(
        ()=>
            topNavItems.find(n=>n.reg.test(location.pathname)) || 'search'
    , [location])

    const changeLocale = useCallback((l, e) => {
        console.log('changeLocale', l, e)
        setLocale(l);
        e?.nativeEvent.preventDefault()
        document.documentElement.classList.remove("nav-open")
        setNavbarCollapse(false)
        setOpenLoginMenu(false);
        if(document.querySelector('.menu-paper')) {
            document.querySelector('.menu-paper').style.left = 0;
        }
    }, [setLocale]);

    React.useEffect(() => {
        const updateNavbarColor = () => {
            if (
                document.documentElement.scrollTop > 299 ||
                document.body.scrollTop > 299
            ) {
                setNavbarColor("");
            } else if (
                document.documentElement.scrollTop < 300 ||
                document.body.scrollTop < 300
            ) {
                setNavbarColor("navbar-transparent");
            }

        };
        window.addEventListener("scroll", updateNavbarColor);
        window.addEventListener("resize", (e)=>{
            if(navbarCollapse) {
                console.log(document.documentElement.offsetWidth);
                setNavbarCollapse(false)
                setOpenLoginMenu(false);
                document.documentElement.classList.remove("nav-open")
                document.querySelector('.navbar-collapse .navbar-nav button')?.classList.remove('d-none')
            }
        });

        return function cleanup() {
            window.removeEventListener("scroll", updateNavbarColor);
        };
    });

    const renderLoginMenu = () => {

        return navbarCollapse? [

            (openLoginMenu?(<NavItem
                className={'login-menu-wrapper'}>
                <NavLink href={`/client/login`} className={'cursor-pointer my-0 mb-1'}>
                    <MDTypography variant="button" fontWeight="bold" sx={{ ml: 1, my: 0 }} textTransform={'uppercase'}>
                        <FormattedMessage
                        id={"pages.login.client"}
                        defaultMessage="Client"
                        description="login client label"
                    />
                    </MDTypography>
                </NavLink>
                    <NavLink href={`/business/login`} className={' cursor-pointer my-0'}>
                    <MDTypography variant="button" fontWeight="bold" sx={{ ml: 1, my: 0 }} textTransform={'uppercase'}>
                        <FormattedMessage id={"pages.login.coach"}  defaultMessage="Coach"/>
                    </MDTypography>
                </NavLink>
            </NavItem>):null)
        ]:(
            <Menu
                style={{width: '330px'}}
                anchorEl={document.querySelector('.submenu:has(.login-nav)') || document.querySelector('li:has(.login-nav)')}
                anchorReference={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                classes={{paper: 'menu-paper'}}
                open={Boolean(openLoginMenu)}
                onClose={handleCloseLoginMenu}
                sx={{mt: -3}}
            >
                <NavLink href={`/client/login`} className={'mb-1 top-menu-link'}>
                    <NotificationItem icon={<PersonIcon/>} title={intl.formatMessage({id: "pages.login.client"})} />
                </NavLink>
                <NavLink href={`/business/login`} className={'top-menu-link'}>
                    <NotificationItem icon={<BusinessCenterIcon/>} title={intl.formatMessage({id: "pages.login.coach"})} />
                </NavLink>
            </Menu>
        );
    }
    const renderMenu = () => {
        return (
            <Drawer

                PaperProps={{style:{width: '360px', boxShadow: 'box-shadow: 0 3px 10px rgb(0 0 0 / 0.3);'}}}
                anchor={'right'}
                classes={{paper: 'menu-paper p-3'}}
                anchorReference={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                open={Boolean(openMenu)}
                onClose={handleCloseMenu}
                sx={{mt: -2, ml: 5}}
            >
                {user && <div className={'menu-avatar-wrapper'}>
                    <ListItemAvatar>
                        <Avatar sx={{ bgcolor: blue[100], color: blue[600] }} src={user.avatar}>
                        </Avatar>
                    </ListItemAvatar>
                   <MDTypography fontSize={18} fontWeight={'medium'} color={'dark'} >{user.fullname?.trim() || user.email}</MDTypography>
               </div>}
                {!user && <NavLink href={`/about`} className={'mb-2 top-menu-link'}>
                    <NotificationItem icon={<HomeIcon/>} title={intl.formatMessage({id: "pages.about"})}/>
                </NavLink>}
                {!user.isBusiness && <NavLink href="/search" className={'mb-2 top-menu-link'}>
                    <NotificationItem icon={<SearchIcon/>} title={intl.formatMessage({id: "pages.search"})}/>
                </NavLink>}
                {user && <NavLink href={`/${user.isBusiness?'business':'client'}/profile`} className={'mb-2 top-menu-link'}>
                    <NotificationItem icon={<PersonIcon/>} title={intl.formatMessage({id: "pages.profile"})}/>
                </NavLink>}
                {user && !user.isBusiness && (
                    <NavLink href={`/client/bookings`} className={'mb-2 top-menu-link'}>
                        <NotificationItem icon={<ScheduleIcon/>} title={intl.formatMessage({id: "pages.bookings.my"})}/>
                    </NavLink>
                )}
                {user.isBusiness && (
                    <NavLink href={`/business/schedule`} className={'mb-2 top-menu-link'}>
                        <NotificationItem icon={<ScheduleIcon/>} title={intl.formatMessage({id: "pages.schedule"})}/>
                    </NavLink>
                )}
                {user.isBusiness && (
                    <NavLink href={`/business/calendar`} className={'mb-2 top-menu-link'}>
                        <NotificationItem icon={<><CalendarIcon/>
                            {bookingsCount > 0 && <span
                                className="navbar-icon-badge">{bookingsCount}</span>}
                        </>} title={intl.formatMessage({id: "pages.calendar"})}/>
                    </NavLink>
                )}
                {user && <NavLink onClick={()=>signout()} className={'mb-2 top-menu-link'}>
                    <NotificationItem icon={<LogoutIcon/>} title={intl.formatMessage({id: "pages.logout"})} />
                </NavLink>}

                {!user && <div className={'submenu'}>
                    <NavLink
                        className={'text-light cursor-pointer login-nav'}
                        onClick={handleOpenLoginMenu}
                        href="#"
                    ><MDTypography color={'inherit'} style={{textTransform: 'uppercase'}} variant={'a'}><FormattedMessage
                        id={"pages.login"}
                        defaultMessage="Login"
                        description="title of the login page"
                    /></MDTypography>

                    </NavLink>
                    {renderLoginMenu()}
                </div>}
                {document.body.offsetWidth < 500 && <div className={'nav-search-combobox nav-language-wrapper'}>
                    <Select
                        value={localeOptions.find(l=>l.value===intl.locale)}
                        components={{
                            DropdownIndicator: ()=> (<svg width="14" height="8" viewBox="0 0 14 8" fill="none"
                                                          xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 1L7 7L13 1" stroke="black" strokeWidth="1.5"
                                          strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            )
                        }}

                        classNames={{
                            control: () => 'nav-lang',
                            indicatorSeparator: () => 'd-none',
                            dropdownIndicator: ()=>'text-dark',
                            indicatorsContainer: ()=>'nav-lang-indicator',
                            singleValue: ()=>'nav-lang-value',
                            menu: () => 'nav-lang-menu',
                        }}
                        options={localeOptions}
                        onChange={({value})=>changeLocale(value)}
                    /></div>}
            </Drawer>
        );
    }

    const Chevron = ()=> (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 18L15 12L9 6" stroke="#0D0314" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    )

    return (
        <Navbar
            color={'white'}
            fixed={'top'}
            color-on-scroll="300"
            expand="lg"
            className={'main-navbar'}
        >
            <Container className={'d-flex'}>
                <div className={classnames("navbar-translate justify-content-center", {
                    toggled: navbarCollapse,
                })}>
                    <NavbarBrand
                        data-placement="bottom"
                        to={user.isBusiness ? "/business/profile" : "/search"}
                        tag={Link}
                    >
                        SpotYa
                    </NavbarBrand>
                    <div className={'d-none d-sm-flex d-lg-none mt-n2'} >
                    {!user && <div className={'auth-none mr-3 text-uppercase'}>
                        <NavLink
                            data-placement="bottom"
                            href="/about"
                            title={intl.formatMessage({id: "pages.about"})}
                            className={'text-uppercase '+ (currentPage.name==='about'?'active':'')}
                            style={{fontSize: 16}}
                        >
                            <FormattedMessage
                                id={"pages.about"}
                                defaultMessage="About us"
                            />
                        </NavLink>
                    </div>}
                    {!user && (<div className={'auth-none text-uppercase'}>
                        <NavLink to="/search" tag={Link} className={currentPage.name==='search'?'active':''}
                                 style={{fontSize: 16}}
                        >
                            <FormattedMessage
                                id={"pages.search"}
                                description="title of the coach search page"
                            />
                        </NavLink>
                    </div>)}
                    </div>
                    <IconButton
                        size="small"
                        disableRipple
                        color="inherit"
                        sx={navbarIconButton2}
                        aria-controls="notification-menu"
                        aria-haspopup="true"
                        variant="contained"
                        className={'navbar-icon-btn'}
                        onClick={handleOpenMenu}
                    >
                        {user.isBusiness && bookingsCount > 0 && <span style={{marginLeft: -36, marginTop: 2,  minWidth: 14, minHeight: 14, height: 14}}
                                                                       className="navbar-icon-badge">!</span>}




                        <MenuIcon fontSize={'large'}  />
                    </IconButton>
                </div>
                <Collapse
                    className="navbar-translate-collapse"
                    navbar
                    isOpen={navbarCollapse}
                >
                    <Nav navbar className="justify-content-center">
                        <MDBox
                            display={{xs: "flex", lg: "none"}}
                            width="100%"
                            height="56px"
                            onClick={toggleNavbarCollapse}
                        >
                            <svg style={{position: 'absolute', right: 8, top: 8}} width="32" height="32" viewBox="0 0 32 32" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.00008 25L24.9706 8.02944" stroke="#0D0314" strokeWidth="1.5"
                                      strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M16 16H16.0001" stroke="#0D0314" strokeWidth="1.5" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                                <path d="M8.00008 8L24.9706 24.9706" stroke="#0D0314" strokeWidth="1.5"
                                      strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>

                        </MDBox>

                        {user && (
                            <div className={'profile-header-container'}>
                                <IconButton
                                    size="small"
                                    disableRipple
                                    color="inherit"
                                    sx={navbarIconButton}
                                    aria-controls="notification-menu"
                                    aria-haspopup="true"
                                    variant="contained"
                                    className={'navbar-icon-btn'}
                                    onClick={handleOpenMenu}
                                >
                                    <ListItemAvatar>
                                        <Avatar sx={{ bgcolor: blue[100], color: blue[600] }} src={user.avatar}>
                                        </Avatar>
                                    </ListItemAvatar>
                                        <MDTypography fontSize={18} fontWeight={'medium'} color={'dark'} >{user.fullname?.trim() || user.email}</MDTypography>
                                </IconButton>
                                {renderMenu()}
                            </div>
                        )}


                        {!user && <NavItem className={'auth-none'}>
                            <NavLink
                                data-placement="bottom"
                                href="/about"
                                title={intl.formatMessage({id: "pages.about"})}
                                className={currentPage.name==='about'?'active':''}
                            >
                                <FormattedMessage
                                    id={"pages.about"}
                                    defaultMessage="About us"
                                />
                                <Chevron/>
                            </NavLink>
                        </NavItem>}
                        {!user && (<NavItem className={'auth-none'}>
                            <NavLink to="/search" tag={Link} className={currentPage.name==='search'?'active':''}>
                                <FormattedMessage
                                id={"pages.search"}
                                description="title of the coach search page"
                            />
                                <Chevron/>
                            </NavLink>
                        </NavItem>)}
                        {!user && renderMenu()}

                            {/*<MDBox
                                data-placement="bottom"
                                title="Language selector"
                                className={'p-3 font-weight-bold cursor-pointer'}
                                sx={{
                                    fontSize: '1.25em',
                                    paddingTop: {lg: !user?.isBusiness?'26px !important':''},
                                    color:  (theme) =>( {
                                        xs:  theme.palette.dark.main,
                                        lg: theme.palette.light.main,
                                    })
                                }}
                            >
                                <span className={locale==='en'?'text-danger':''} onClick={(e)=>changeLocale('en', e)}>EN</span> / <span className={locale==='lt'?'text-danger':''} onClick={(e)=>changeLocale('lt', e)}>LT</span>
                            </MDBox>*/}
                    </Nav>
                    <Nav navbar className={'align-self-end'}  >
                        <NavItem className={'nav-search-combobox'}>
                            <Select
                                value={localeOptions.find(l=>l.value===intl.locale)}
                                components={{
                                    DropdownIndicator: ()=> (<svg width="14" height="8" viewBox="0 0 14 8" fill="none"
                                                                  xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 1L7 7L13 1" stroke="black" strokeWidth="1.5"
                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    )
                                }}
                                classNames={{
                                    control: () => 'nav-lang',
                                    indicatorSeparator: () => 'd-none',
                                    dropdownIndicator: ()=>'text-dark',
                                    indicatorsContainer: ()=>'nav-lang-indicator',
                                    singleValue: ()=>'nav-lang-value',
                                }}
                                options={localeOptions}
                                onChange={({value})=>changeLocale(value)}
                            /></NavItem>
                        {user && (
                            <div className={'profile-header-container profile-header-container-auth'}>
                                <IconButton
                                    size="small"
                                    disableRipple
                                    color="inherit"
                                    sx={navbarIconButton}
                                    aria-controls="notification-menu"
                                    aria-haspopup="true"
                                    variant="contained"
                                    className={'navbar-icon-btn'}
                                    onClick={handleOpenMenu}
                                >
                                    {user.isBusiness && bookingsCount > 0 && <span style={{marginLeft: -36, marginTop: 2,  minWidth: 14, minHeight: 14, height: 14}}
                                                                                   className="navbar-icon-badge">!</span>}

                                    <MenuIcon fontSize={'large'}/>
                                    {/*<ListItemAvatar>
                                        <Avatar sx={{ bgcolor: blue[100], color: blue[600] }} src={user.avatar}>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <MDTypography fontSize={18} fontWeight={600} color={'dark'} >{user.fullname?.trim() || user.email}</MDTypography>*/}
                                </IconButton>
                                {renderMenu()}
                            </div>
                        )}
                        {!user && <NavItem>
                            <NavLink
                                className={'text-light cursor-pointer login-nav'}
                                onClick={handleOpenLoginMenu}
                                href="#"
                            ><MDTypography color={'inherit'} style={{textTransform: 'uppercase'}} variant={'a'}><FormattedMessage
                                id={"pages.login"}
                                defaultMessage="Login"
                                description="title of the login page"
                            /></MDTypography>

                            </NavLink>
                            {renderLoginMenu()}
                        </NavItem>}

                    </Nav>

                </Collapse>
            </Container>
        </Navbar>
    );
}


export default ExamplesNavbar;
