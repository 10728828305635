/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import React, {useCallback, useEffect, useMemo, useState} from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import ButtonGroup from "@mui/material/ButtonGroup";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import FormField from "views/examples/account/components/FormField";
// Settings page components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import BasicInfo, {capitalizeFirstLetter} from "views/examples/account/settings/components/BasicInfo";
import ProfileInfoCard from "views/examples/Cards/InfoCards/ProfileInfoCard";
import {
    Container,
} from "reactstrap";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useAuth} from "useAuth";
import _ from "lodash";
import {API} from "api";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import PulseLoader from "react-spinners/PulseLoader";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import ImgsViewer from "../../../lib/react-images-viewer/src/ImgsViewer";
import Backdrop from "@mui/material/Backdrop";
import { Rating } from 'react-simple-star-rating'
import SectionCarousel from "views/index-sections/SectionCarousel";
import BookingCalendarNew from "views/examples/coach/BookingCalendarNew";
import Swal2 from "sweetalert2";
import MDBadge from "../../../components/MDBadge";
import StarIcon from "@mui/icons-material/Star";
import {FormattedMessage, useIntl} from "react-intl";
import {RegisterCTA} from "../auth/RegisterCTA";
import selectData from "../account/settings/components/BasicInfo/data/selectData";
import {MediaViewer} from "./MediaViewer";
import MDAlert from "../../../components/MDAlert";
import {ArrowRight, ChevronRight, Warning} from "@mui/icons-material";
import {Paper} from "@mui/material";

const api = new API()


const tabsMap = {
    'anketa': 0,
    'grafikas': 1
}

const tabsMap2 = {
    'description': 0,
    'photo': 1,
    'reviews': 2,
}

const profilePage = document.location.href.indexOf('-preview')===-1?'coach-profile':'coach-profile-preview';

function ProfileViewPage({}) {
    const {user} = useAuth();
    const intl = useIntl();
    const {locale} = useIntl();
    const params = useParams();
    const navigate = useNavigate();
    const [tab, setTab] = useState(params.tab ? tabsMap[params.tab] : 0);
    const [tab2, setTab2] = useState(params.tab2 ? tabsMap2[params.tab2] : 0);
    const [coach, setCoach] = useState();
    const [events, setEvents] = useState([]);
    const [imgsViewerCurrent, setImgsViewerCurrent] = useState(0);
    const [imgsViewer, setImgsViewer] = useState(false);

    const [loading, setLoading] = useState(false);

    const [form, setForm] = React.useState({name: user?.fullname, email: user?.email});
    const setFormField = useCallback((name, value) => {
        setForm(f => {
            return ({...f, [name]: value?.target ? value.target.value : value})
        })
    }, [form])

    const fetchCoach = async () => {
        const data = localStorage.getItem(`${profilePage}`) || '{}'
        const coachData = (await api.get(`users/coach/${params.id}`));
        setCoach({...coachData, ...JSON.parse(data), isPreview: true})
        if (data) {
            localStorage.setItem(`${profilePage}`, '{}')
        }
        const newEvents = (await api.get(`bookings`))?.filter(b=>b.coachId===coachData.uuid).map(event => _.pick({
            ...event,
            title: ``,
            start: `${event.startDate}T${(event.timeStart).toString().padStart(2, '0')}:00:00`,
            end: `${event.startDate}T${(event.timeEnd).toString().padStart(2, '0')}:00:00`,
            id: `${event.uuid}`,
            eventId: `${event.uuid}`,
            editable: false,
            display: 'background',
            className: `event-session`,
            isPreview: false,
            savedEvent: true,
        }, ['uuid', 'coachId', 'bookings', 'maxUsers', 'savedEvent', 'price', 'startDate', 'endDate', 'timeStart', 'timeEnd', 'recurring', 'recurringId', 'recurringWeekAmount', 'activity', 'location', 'employee', 'title', 'start', 'end', 'eventId', 'status', 'editable', 'display', 'className', 'isPreview'])) || [];

        setEvents(newEvents)
    }
    const handleSaveReview = async () => {
        const missing = ['email', 'name', 'comment', 'rate'].filter(s=>!form[s])
        if(missing.length > 0) {
            Swal2.fire('Not all required fields are set', missing.map(fld => _.capitalize(fld)).join(', '), 'warning')
        } else {
            const res = await api.post('reviews', {...form, coachId: params.id})
            setFormField('comment', '')
            setFormField('rate', 0)
            await fetchCoach();
        }
    }
    const handleUpdateReview = async (reviewId, status) => {
        const res = await api.put(`reviews/${reviewId}`, {status})
        await fetchCoach();
    }

    useEffect(() => {
        fetchCoach()
    }, [])
    useEffect(() => {
        setTab(params.tab ? tabsMap[params.tab] : 0)
        setTab2(params.tab2 ? tabsMap2[params.tab2] : 0)
    }, [params.tab, params.tab2])

    const getReviewFontSize = useCallback((size)=> {
        const defSize = params.tab==='grafikas'?500:250;
        const resultSize = 21 * Math.sqrt(defSize / (size>defSize ? size: defSize))
        return resultSize > 12 ? resultSize : 12
    }, [tab])

    const isCoach = useMemo(()=>user?.uuid===coach?.uuid, [user, coach]);

    return coach ? (
        <>
            <ExamplesNavbar/>
            <MDBox className={` ${tab===0 ? 'profile-container-new' : 'container'} calendar-container-new  mt-navbar mb-footer position-relative`}
                   sx={{...((tab === 1&&user) ? {maxWidth: {xs: '100%', xxl: '95%', xxxl: '90%'}, paddingX: {xs: '2px', xxxl: '15px'}} : {})}}>
                <MDButton style={{marginTop: -60}} onClick={() => navigate(-1)} variant={'outlined'} size={'medium'}
                          color={'dark'}><ArrowBackIcon/> <FormattedMessage id={"common.back"}/></MDButton>
                <Card sx={{...((tab === 1&&user) ? { paddingX: {xs: '2px', xxxl: '15px'}} : {})}}
                         style={{height: '100%', marginTop: 20, paddingBottom: 20}}
                         >
                    <MDBox
                        borderRadius="lg"
                        shadow="md"
                        elevation={2}
                        height={60}
                        top={-30}
                        bgColor={'white'}
                        position="relative"
                        zIndex={1}
                        left={-10}
                        width={'calc(100% + 20px)'}
                    >
                        <MDBox textAlign={'center'} px={2} pl={4} lineHeight={1} className={'d-flex w-100 justify-content-between h-100 align-items-center'}>
                            <MDTypography color={'dark'} variant="h6" fontWeight="medium" textTransform="capitalize">
                                {coach?.fullname}
                            </MDTypography>

                            {tab===0 && <MDButton onClick={() => {
                                    setTab(1);
                                    navigate(`/${profilePage}/${params.id}/grafikas`)
                                }} sx={{
                                    marginLeft: 2,
                                    maxWidth: {
                                        xs:'50%',
                                        md: '100%'
                                    },
                                    maxHeight: {
                                        xs: '90%',
                                        md: 'initial !important'
                                    },
                                    fontSize: 12,
                                    float: {
                                        xs: 'center',
                                        lg: 'right',
                                    }
                                }} variant={'gradient'} color={'primary'}>
                                    <FormattedMessage id={"common.booking-cta"}
                                                      defaultMessage={'Book a time for coaching session'}/>
                                </MDButton>}
                            {tab===1 && <MDButton onClick={() => {
                                    setTab(1);
                                    navigate(`/${profilePage}/${params.id}/anketa`)
                                }} sx={{
                                    float: {
                                        xs: 'center',
                                        lg: 'right',
                                    }
                                }} variant={'gradient'} color={'primary'}>
                                    <FormattedMessage id={"common.coach"} />
                                </MDButton>}

                        </MDBox>
                    </MDBox>
                    <MDBox sx={{paddingX: {xs: '1px', xxl: '24px'}, paddingY: 0, marginTop: 1}}>
                        {tab === 0 && <MDBox component={'div'} className={'tab0-wrapper'}>
                            {user && !isCoach && user.bookingsCount < 1 &&
                                <MDAlert className={'no-bookings-alert'} style={{padding: '0 12px', minHeight: 50, height: 50, fontWeight: 400}}
                                         dismissible={true} color={'primary'}>
                                    <div style={{cursor: 'pointer'}} onClick={() => {
                                        setTab(1);
                                        navigate(`/${profilePage}/${params.id}/grafikas`)
                                    }}><Warning fontSize={'large'} style={{marginTop:-5}}/>&nbsp;<FormattedMessage
                                        id={"common.booking-cta-alert"}/></div>
                                </MDAlert>}
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={3}>
                                    <MDAvatar src={coach.avatar} className={'profile-avatar'} alt="profile-image"
                                              size={"x-250"} shadow="sm"/>
                                    <div className={'d-block d-sm-none profile-buttons-wrapper '} style={{maxWidth: 180, position:'absolute', right: 20}}>
                                        {[intl.formatMessage({id: 'common.description'}), intl.formatMessage({id: 'common.photovideo'}), `${intl.formatMessage({id: 'common.reviews'})}${coach.reviews.length ? ` (${coach.reviews.length})` : ''}`].map((l, i) => (
                                            <MDButton

                                                key={i} variant={tab2 === i ? 'contained' : 'contained'}

                                                onClick={() => {

                                                    setTab2(i)
                                                    navigate(`/${profilePage}/${params.id}/${params.tab || 'anketa'}/${Object.keys(tabsMap2).find(key => tabsMap2[key] === i)}`)
                                                }}
                                                className={' m-1'}
                                                color={tab2 === i ? 'warning' : 'light'} fullWidth>{l}</MDButton>))}
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={9}>
                                    <MDBox
                                        borderRadius="lg"
                                        shadow1="md"
                                        height={60}
                                        top={-30}
                                        position="relative"
                                        zIndex={1}
                                    >
                                        <MDBox
                                            position={'absolute'}
                                            width={'calc(100% + 54px)'}
                                            pt={2}
                                            textAlign={'center'}
                                            px={2}
                                            lineHeight={1}
                                        >

                                            <div className={'d-none d-sm-block'}>
                                                <ButtonGroup
                                                    component={Paper}
                                                    elevation={2}
                                                    fullWidth
                                                    className={'profile-tabs-1'}
                                                sx={{
                                                    borderRadius: 2,
                                                    transform: { xs: 'scale(0.65,0.65) translateX(-22.75%)', md: 'scale(0.75,0.75) translateX(-18.75%)', xl: 'unset'},
                                            }}
                                            >
                                                {[intl.formatMessage({id: 'common.description'}), intl.formatMessage({id: 'common.photovideo'}), `${intl.formatMessage({id: 'common.reviews'})}${coach.reviews.length?` (${coach.reviews.length})`:''}`].map((l, i) => (
                                                    <MDButton

                                                        key={i} variant={tab2 === i ? 'contained' : 'contained'}
                                                              style={{borderLeft: tab2 === i ? '' : 'solid 0.25px #cec7f9'}}
                                                        onClick={() => {

                                                        setTab2(i)
                                                        navigate(`/${profilePage}/${params.id}/${params.tab || 'anketa'}/${Object.keys(tabsMap2).find(key => tabsMap2[key] === i)}`)
                                                    }}
                                                              size={'large'}
                                                              className={'btn-group-tabs'}
                                                              color={tab2 === i ? 'warning' : 'white'} fullWidth>{l}</MDButton>))}
                                            </ButtonGroup>
                                            </div>
                                        </MDBox>
                                    </MDBox>
                                    {tab2===0 && <ProfileInfoCard
                                        title={intl.formatMessage({id: 'common.profileinfo'})}
                                        description={coach.summary}
                                        style={{backgroundColor: 'transparent'}}
                                        social={[
                                            {
                                                link: coach.coachData?.fbURL,
                                                icon: <FacebookIcon />,
                                                color: "facebook",
                                            },
                                            {
                                                link: coach.coachData?.instagramURL,
                                                icon: <InstagramIcon />,
                                                color: "instagram",
                                            },
                                        ]}
                                        locations={coach.coachData.locations || []}
                                        instagramURL={coach.coachData.instagramURL}
                                        fbURL={coach.coachData.fbURL}
                                        info={{
                                            name: coach.name,
                                            surname: coach.surname,
                                            languages: coach.languages?.map(l=>capitalizeFirstLetter(intl.formatDisplayName(selectData.languageCodes.find(c=>c.name===l).code, {type:'language'}))).join(', ') || '',
                                            mobile: coach.phone || 'n/a',
                                            email: coach.contactEmail || 'n/a',
                                            pricing: <>{((coach.minPrice!==coach.maxPrice)&&<>{locale === 'lt'&&'Nuo '}&euro;{coach.minPrice} {locale === 'lt'?'iki':'-'} </>)}&euro;{coach.maxPrice} {intl.formatMessage({id: 'common.perhour'})}</>,
                                            address: coach.coachData.locations?.length > 0 ? undefined : coach.location,
                                            'locations': coach.coachData.locations?.map(l => l.name).join(", "),
                                        }}
                                        shadow={false}
                                    />}

                                    {tab2 === 1 && <MDBox component={'div'}>
                                        <MediaViewer items={coach.photos}/>
                                    </MDBox>}

                                    {tab2 === 2 && <MDBox component={'div'}>
                                        <MDBox  pb={0} px={3}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} md={6} className={'review-box'}>
                                                    {coach.reviews.length === 0 && <div className={'text-secondary  font-italic text-center'}>{intl.formatMessage({id: "common.noreviews"})}...</div>}
                                                    <SectionCarousel items={coach.reviews.map((review) => (
                                                        <MDBox key={review.uuid} className={'border-info border-1 border p-3'} sx={{borderRadius: 10, margin: '', height: 400}}>
                                                            <MDBox className={'my-2'}>
                                                                <MDBadge color={'success'} className={'badge-card-marker'}
                                                                         badgeContent={
                                                                             <MDBox width={'100%'} display="flex"
                                                                                    justifyContent="space-between"
                                                                                    alignItems="space-between">
                                                                                 <MDTypography variant="h6" className={'h6-bottom'}
                                                                                               color="white" fontWeight="medium">
                                                                                     <StarIcon color={'gold'}
                                                                                               style={{marginTop: -4}}
                                                                                               fontSize={'small'}/> {parseFloat(review.rate).toFixed(1)}
                                                                                 </MDTypography>
                                                                             </MDBox>
                                                                         } container/>
                                                            </MDBox>
                                                            <MDBox>
                                                                <MDBadge color={'light'} className={'text-muted ml-0'} container
                                                                         badgeContent={new Date(review.createdAt).toLocaleString()}/></MDBox>
                                                            <MDTypography component={'div'} sx={{fontSize:  getReviewFontSize(review.comment.length), height: 230, overflowY: 'auto'}} variant={'p'} className={'review-comment-box text-justify'}>
                                                                {review.comment}
                                                            </MDTypography>
                                                            <MDTypography variant={'h4'} className={'text-center mt-3'}>
                                                                {review.name}
                                                            </MDTypography>
                                                            {review.coachId === user.uuid &&
                                                                <MDBox className={'text-center'}>
                                                                    {review.status === 'pending' && <MDButton variant="gradient" color="success" size="small"
                                                                                                              onClick={() => handleUpdateReview(review.uuid, 'accepted')}>
                                                                        Approve Review
                                                                    </MDButton>}&nbsp;
                                                                    {review.status !=='pending' && <MDBadge color={review.status==='declined'?'error':'info'} badgeContent={<>status: {review.status}</>}/>}
                                                                    {review.status === 'pending' && <MDButton variant="gradient" color="error" size="small"
                                                                                                              onClick={() => handleUpdateReview(review.uuid, 'declined')}>
                                                                        Decline Review
                                                                    </MDButton>}
                                                                </MDBox>}
                                                        </MDBox>))}/>
                                                </Grid>
                                                <Grid item xs={12} md={6} className={'mt-4 mt-md-0'}>
                                                    {!user &&  <MDBox component={'div'} sx={{'button':{justifySelf:'center', alignSelf:'center'}}} className={"text-center h-100"}><RegisterCTA className={'h-100 d-grid'}  from={`/${profilePage}/${coach.uuid}/grafikas`}/></MDBox>}
                                                    {user && user.uuid !== coach.uuid && <Grid container spacing={3}>
                                                        <Grid item xs={12}>
                                                            <FormField variant="outlined" label={intl.formatMessage({id:'common.name'})}
                                                                       inputProps={{
                                                                           value: form.name,
                                                                           onChange: v => setFormField('name', v)
                                                                       }}/>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <FormField variant="outlined" label={intl.formatMessage({id:'common.email'})}
                                                                       inputProps={{
                                                                           type: 'email',
                                                                           value: form.email,
                                                                           onChange: v => setFormField('email', v)
                                                                       }}/>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Rating
                                                                onClick={v => setFormField('rate', v)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <FormField variant="outlined" label={intl.formatMessage({id:'common.comment'})} multiline max={300}
                                                                       rows={6} placeholder="Start typing..."
                                                                       inputProps={{
                                                                           max: 1000,
                                                                           maxLength: 1000,
                                                                           value: form.comment,
                                                                           onChange: v => setFormField('comment', v)
                                                                       }}/>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            {events.length===0 && <div className={'text-warning text-smaller'}>You need at least one booked session with coach to post a review</div>}
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <MDBox ml="auto" mt={3} style={{width: 100}}>
                                                                <MDButton variant="gradient" color="info" size="small"
                                                                          disabled={events.length===0}
                                                                          onClick={handleSaveReview}>
                                                                    SUBMIT
                                                                </MDButton>
                                                            </MDBox>
                                                        </Grid>
                                                    </Grid>}
                                                </Grid>
                                            </Grid>
                                        </MDBox>
                                    </MDBox>}

                                    {tab2 === 3 && <MDBox component={'div'}>
                                        <Grid container spacing={3}>
                                            <ImgsViewer
                                                imgs={(coach.certificates || []).map(item => ({
                                                    src: item.url,
                                                    srcSet: [item.url],
                                                    caption: item.caption || ''
                                                }))}
                                                isOpen={imgsViewer}
                                                currImg={imgsViewerCurrent}
                                                onClickNext={() => setImgsViewerCurrent(imgsViewerCurrent + 1)}
                                                onClickPrev={() => setImgsViewerCurrent(imgsViewerCurrent - 1)}
                                                onClose={() => setImgsViewer(false)}
                                                backdropCloseable
                                            />
                                            {(coach.certificates || []).map((c, i) => (<Grid key={i} item xs={12} sm={6} md={4}>
                                                <MDBox
                                                    component="img"
                                                    id={c.url}
                                                    src={c.url}
                                                    alt={c.caption || c.url}
                                                    borderRadius="lg"
                                                    shadow="md"
                                                    width="100%"
                                                    height="100%"
                                                    sx={{cursor: "pointer", objectFit: "contain", backgroundSize: 'auto 100%'}}
                                                    onClick={(item) => {
                                                        setImgsViewerCurrent(i)
                                                        setImgsViewer(true)
                                                    }}
                                                />
                                            </Grid>))}
                                        </Grid>
                                    </MDBox>}
                                </Grid>
                            </Grid>
                        </MDBox>}
                        {tab === 1 && !user && <MDBox component={'div'} className={"text-center pb-3"}><RegisterCTA from={`/${profilePage}/${coach.uuid}/grafikas`}/></MDBox>}
                        {tab === 1 && user && <MDBox component={'div'} className={"profile-calendar"} sx={{

                        }}>
                            {loading && <Backdrop
                                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                                open={true}
                                onClick={() => setLoading(false)}
                            ><PulseLoader color={'#9180ff'} size={18}/></Backdrop>}
                            <BookingCalendarNew coach={coach} eventsArray={events} setLoading={setLoading}>
                            </BookingCalendarNew>
                        </MDBox>}
                    </MDBox>
                </Card>
                {tab===2 && <Card style={{height: '100%', marginTop: 30}} className={'px-1 px-sm-2 px-md-3 pb-4'}>
                    <MDBox
                        borderRadius="lg"
                        shadow1="md"
                        height={60}
                        top={-30}
                        position="relative"
                        zIndex={1}
                    >
                        <MDBox pt={2} textAlign={'center'} px={2} lineHeight={1}>
                            <ButtonGroup fullWidth>
                                {[intl.formatMessage({id: 'common.description'}), intl.formatMessage({id: 'common.photovideo'}), `${intl.formatMessage({id: 'common.reviews'})}${coach.reviews.length?` (${coach.reviews.length})`:''}`, intl.formatMessage({id: 'common.certificates'})].map((l, i) => (
                                    <MDButton key={i} variant={tab2 === i ? 'contained' : 'contained'}
                                              style={{borderLeft: tab2 === i ? '' : 'solid 0.25px #cec7f9'}}
                                              sx={{zoom: {xs: 0.5, sm: 0.55, md: 0.75, lg: 1}}} onClick={() => {

                                                    setTab2(i)
                                                    navigate(`/${profilePage}/${params.id}/${params.tab || 'anketa'}/${Object.keys(tabsMap2).find(key => tabsMap2[key] === i)}`)
                                                }}
                                              size={'large'}
                                              className={'btn-group-tabs'}
                                              color={tab2 === i ? 'warning' : 'violet'} fullWidth>{l}</MDButton>))}
                            </ButtonGroup>

                        </MDBox>
                    </MDBox>
                    <MDBox p={3} mb={3}>
                        {tab2 === 0 && <MDBox component={'div'} className={'descr-tab-text'}>
                            {coach.description?(new DOMParser().parseFromString(coach.description , 'text/html').body.textContent):''}
                        </MDBox>}

                        {tab2 === 1 && <MDBox component={'div'}>
                            <MediaViewer items={coach.photos}/>
                        </MDBox>}

                        {tab2 === 2 && <MDBox component={'div'}>
                            <MDBox  pb={0} px={3}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6} className={'review-box'}>
                                        {coach.reviews.length === 0 && <div className={'text-secondary  font-italic text-center'}>{intl.formatMessage({id: "common.noreviews"})}...</div>}
                                        <SectionCarousel items={coach.reviews.map((review) => (
                                            <MDBox key={review.uuid} className={'border-info border-1 border p-3'} sx={{borderRadius: 10, margin: '', height: 400}}>
                                                <MDBox className={'my-2'}>
                                                    <MDBadge color={'success'} className={'badge-card-marker'}
                                                             badgeContent={
                                                                 <MDBox width={'100%'} display="flex"
                                                                        justifyContent="space-between"
                                                                        alignItems="space-between">
                                                                     <MDTypography variant="h6" className={'h6-bottom'}
                                                                                   color="white" fontWeight="medium">
                                                                         <StarIcon color={'gold'}
                                                                                   style={{marginTop: -4}}
                                                                                   fontSize={'small'}/> {parseFloat(review.rate).toFixed(1)}
                                                                     </MDTypography>
                                                                 </MDBox>
                                                             } container/>
                                                </MDBox>
                                                <MDBox>
                                                    <MDBadge color={'light'} className={'text-muted ml-0'} container
                                                             badgeContent={new Date(review.createdAt).toLocaleString()}/></MDBox>
                                                <MDTypography component={'div'} sx={{fontSize:  getReviewFontSize(review.comment.length), height: 230, overflowY: 'auto'}} variant={'p'} className={'review-comment-box text-justify'}>
                                                    {review.comment}
                                                </MDTypography>
                                                <MDTypography variant={'h4'} className={'text-center mt-3'}>
                                                    {review.name}
                                                </MDTypography>
                                                {review.coachId === user.uuid &&
                                                    <MDBox className={'text-center'}>
                                                        {review.status === 'pending' && <MDButton variant="gradient" color="success" size="small"
                                                                  onClick={() => handleUpdateReview(review.uuid, 'accepted')}>
                                                            Approve Review
                                                        </MDButton>}&nbsp;
                                                        {review.status !=='pending' && <MDBadge color={review.status==='declined'?'error':'info'} badgeContent={<>status: {review.status}</>}/>}
                                                        {review.status === 'pending' && <MDButton variant="gradient" color="error" size="small"
                                                                  onClick={() => handleUpdateReview(review.uuid, 'declined')}>
                                                            Decline Review
                                                        </MDButton>}
                                                    </MDBox>}
                                            </MDBox>))}/>
                                    </Grid>
                                    <Grid item xs={12} md={6} className={'mt-4 mt-md-0'}>
                                        {!user &&  <MDBox component={'div'} sx={{'button':{justifySelf:'center', alignSelf:'center'}}} className={"text-center h-100"}><RegisterCTA className={'h-100 d-grid'}  from={`/${profilePage}/${coach.uuid}/grafikas`}/></MDBox>}
                                        {user && user.uuid !== coach.uuid && <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <FormField variant="outlined" label={intl.formatMessage({id:'common.name'})}
                                                           inputProps={{
                                                               value: form.name,
                                                               onChange: v => setFormField('name', v)
                                                           }}/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormField variant="outlined" label={intl.formatMessage({id:'common.email'})}
                                                           inputProps={{
                                                               type: 'email',
                                                               value: form.email,
                                                               onChange: v => setFormField('email', v)
                                                           }}/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Rating
                                                    onClick={v => setFormField('rate', v)}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormField variant="outlined" label={intl.formatMessage({id:'common.comment'})} multiline max={300}
                                                           rows={6} placeholder="Start typing..."
                                                           inputProps={{
                                                               max: 1000,
                                                               maxLength: 1000,
                                                               value: form.comment,
                                                               onChange: v => setFormField('comment', v)
                                                           }}/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {events.length===0 && <div className={'text-warning text-smaller'}>You need at least one booked session with coach to post a review</div>}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <MDBox ml="auto" mt={3} style={{width: 100}}>
                                                    <MDButton variant="gradient" color="info" size="small"
                                                              disabled={events.length===0}
                                                              onClick={handleSaveReview}>
                                                        SUBMIT
                                                    </MDButton>
                                                </MDBox>
                                            </Grid>
                                        </Grid>}
                                    </Grid>
                                </Grid>
                            </MDBox>
                        </MDBox>}

                        {tab2 === 3 && <MDBox component={'div'}>
                            <Grid container spacing={3}>
                                <ImgsViewer
                                    imgs={(coach.certificates || []).map(item => ({
                                        src: item.url,
                                        srcSet: [item.url],
                                        caption: item.caption || ''
                                    }))}
                                    isOpen={imgsViewer}
                                    currImg={imgsViewerCurrent}
                                    onClickNext={() => setImgsViewerCurrent(imgsViewerCurrent + 1)}
                                    onClickPrev={() => setImgsViewerCurrent(imgsViewerCurrent - 1)}
                                    onClose={() => setImgsViewer(false)}
                                    backdropCloseable
                                />
                                {(coach.certificates || []).map((c, i) => (<Grid key={i} item xs={12} sm={6} md={4}>
                                    <MDBox
                                        component="img"
                                        id={c.url}
                                        src={c.url}
                                        alt={c.caption || c.url}
                                        borderRadius="lg"
                                        shadow="md"
                                        width="100%"
                                        height="100%"
                                        sx={{cursor: "pointer", objectFit: "contain", backgroundSize: 'auto 100%'}}
                                        onClick={(item) => {
                                            setImgsViewerCurrent(i)
                                            setImgsViewer(true)
                                        }}
                                    />
                                </Grid>))}
                            </Grid>
                        </MDBox>}


                    </MDBox>
                </Card>}


            </MDBox>
            <DemoFooter/>
        </>
    ) : (<Container fluid={true} className={'p-5 text-center min-vh-100'}><PulseLoader color={'#9180ff'}
                                                                                       size={18}/></Container>);
}

export default ProfileViewPage;
