/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";


// Setting pages components
import TableCell from "views/examples/account/settings/components/TableCell";
import React, {useCallback} from "react";
import _ from "lodash";
import Swal2 from "sweetalert2";
import {API} from "api";
import {FormattedMessage, useIntl} from "react-intl";

const api = new API()
function Specials({form, setFormField, fetchUser}) {
  const intl = useIntl();
  const handleSave = useCallback(async () => {
    await api.post(`users/${form.uuid}`, _.pick(form, ['coachData']))
    await fetchUser()
    Swal2.fire({title: intl.formatMessage({id: 'common.alert.saved'}), html: intl.formatMessage({id: 'common.profile.updated'}), icon: 'success',confirmButtonText: intl.formatMessage({id: 'common.confirm'})})
  }, [form]);


  return (
    <Card id="notifications">
      <MDBox p={3} lineHeight={1}>
        <MDBox mb={1}>
          <MDTypography variant="h5"><FormattedMessage id={"forms.special"}/></MDTypography>
        </MDBox>
        <MDTypography variant="button" color="text">
          <FormattedMessage id={"forms.special.subtitle"}/>
        </MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <MDBox minWidth="auto">
          <Table sx={{ }}>
            <TableBody>
                <TableRow>
                  <TableCell width="100%" padding={[1.5, 3, 1.5, 0.5]}>
                    <FormattedMessage id={"forms.special.type"}/>
                  </TableCell>
                  <TableCell align="center" padding={[1.5, 6, 1.5, 6]}>

                  </TableCell>
                </TableRow>
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <MDBox lineHeight={1.4}>
                    <MDTypography display="block" variant="button" fontWeight="regular">
                      <FormattedMessage id={"forms.special.type.individual"}/>
                    </MDTypography>
                  </MDBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch size={'large'} checked={form.coachData.specials?.individual} onChange={(e, checked)=>setFormField('coachData.specials.individual', checked)} />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <MDBox lineHeight={1.4}>
                    <MDTypography display="block" variant="button" fontWeight="regular">
                      <FormattedMessage id={"forms.special.type.group"}/>
                    </MDTypography>
                  </MDBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch size={'large'} checked={form.coachData.specials?.group} onChange={(e, checked)=>setFormField('coachData.specials.group', checked)} />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <MDBox lineHeight={1.4}>
                    <MDTypography display="block" variant="button" fontWeight="regular">
                      <FormattedMessage id={"forms.special.type.special"}/>
                    </MDTypography>
                  </MDBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch size={'large'} checked={form.coachData.specials?.special} onChange={(e, checked)=>setFormField('coachData.specials.special', checked)} />
                </TableCell>
              </TableRow>

                <TableRow colSpan={2}>
                  <TableCell width="100%" padding={[1.5, 3, 1.5, 0.5]}>
                    <FormattedMessage id={"forms.special.benefits"}/>
                  </TableCell>
                </TableRow>


              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <MDBox lineHeight={1.4}>
                    <MDTypography display="block" variant="button" fontWeight="regular">
                      <FormattedMessage id={"forms.special.benefits.food"}/>
                    </MDTypography>
                  </MDBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch size={'large'} checked={form.coachData.specials?.food} onChange={(e, checked)=>setFormField('coachData.specials.food', checked)} />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <MDBox lineHeight={1.4}>
                    <MDTypography display="block" variant="button" fontWeight="regular">
                      <FormattedMessage id={"forms.special.benefits.programs"}/>
                    </MDTypography>
                  </MDBox>
                </TableCell>
                <TableCell align="center" >
                  <Switch size={'large'} checked={form.coachData.specials?.sports} onChange={(e, checked)=>setFormField('coachData.specials.sports', checked)} />
                </TableCell>
              </TableRow>

            </TableBody>
          </Table>
          <MDBox ml="auto" mt={3} style={{textAlign: 'right'}}>
            <MDButton variant="gradient" color="info" size="small" onClick={handleSave}>
              <FormattedMessage id={"forms.special.save"}/>
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Specials;
