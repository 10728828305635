/*!

=========================================================
* Paper Kit React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useCallback, useEffect, useRef, useState} from "react";

// reactstrap components
import {
    Button,
    Label,
    FormGroup,
    Input,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
    Container,
    Row,
    Col, Form, CustomInput, Collapse, Navbar,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import HeaderMap from "components/Headers/HeaderMap.js";
import Slider from "nouislider";
import "nouislider/dist/nouislider.min.css";


import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import PulseLoader from "react-spinners/PulseLoader";
import MDAvatar from "components/MDAvatar";
import Calendar from "views/examples/Calendar";
import MDBox from "components/MDBox";

import tz from "moment-timezone";
import moment from "moment";
import Swal2 from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import _ from "lodash";
import {API} from "api";
import {FormattedMessage, useIntl} from "react-intl";
import {useDictionaries} from "lib/dictionaries";
import {useAuth} from "../../../useAuth";
import {useNavigate} from "react-router-dom";
import {useLocale} from "../../../useLocale";
import {Paper} from "@mui/material";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Tooltip from "@mui/material/Tooltip";



const api = new API();
const initialScreenSize = window.innerWidth;

function CalendarPage() {
    const Swal = withReactContent(Swal2);
    const [events, setEvents] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [filter, setFilter] = React.useState({});
    const [selected, setSelectedState] = React.useState(null);
    const [opacity, setOpacity] = React.useState(0);
    const mapRef = useRef();
    const scrollRef = useRef();
    const [minCardHeight, setMinCardHeight] = useState('100vh');
    let calendarRef = null
    const intl = useIntl()
    const {locale, setLocale} = useLocale()
    const {user} = useAuth()
    const {statusesDict, statusesMap} = useDictionaries()

    const fetchBookings = async () => {
        const bookings = await api.get(`bookings`) || [];
        setEvents([])
        const eventsData = bookings.map(b=>({
            ...b.scheduleData,
            ...b,
            uuid: b.uuid,
            image: b.coach.avatar,
            start: `${b.bookingDate}T${(b.timeStart)}:00`,
            end: `${b.bookingDate}T${b.timeEnd}:00`,
            endTime: null,
            startTime: null,
            eventId: `${b.uuid}`,
            display: 'list-item',
            className: `event-${statusesDict.find(s=>s.status===b.status)?.color}`
        }));
        console.log('eventsData',eventsData, intl.locale)
        setTimeout(()=>setEvents(eventsData),300)
    }

    const getRef = (ref) => {
        calendarRef = ref;
    }
    useEffect(()=>{
        fetchBookings()
    }, [])
    useEffect(()=>{
        const calendarAPI = calendarRef?.getApi?.()
        if(calendarAPI) {
            calendarAPI.on('_resize', ()=>{
                const api = calendarRef?.getApi?.()
                //setMinCardHeight(api.currentData.currentViewType === "timeGridWeek"?'100vh':1280)
                api.currentData.currentViewType === "timeGridWeek"?api.currentClassNames.push('week-view'):_.pull(api.currentClassNames, 'week-view')
                if(api.currentData.currentViewType !== "timeGridWeek") {
                    document.querySelector('.fc-today-button').classList.add('d-none')
                } else {
                    document.querySelector('.fc-today-button').classList.remove('d-none')
                }


                api.currentData.currentViewType === "timeGridWeek" ? api.currentClassNames.push('week-view') : _.pull(api.currentClassNames, 'week-view')
                let zoom=0;
                if(window.innerWidth<=1260) {
                    zoom='90%'
                }
                if(window.innerWidth<=900) {
                    zoom='80%'
                }
                setTimeout(()=>{
                    if(api.currentData.currentViewType === "timeGridWeek" && zoom && document.querySelector('.fc') && document.querySelector('.fc')?.style.zoom!==zoom) {
                        document.querySelector('.fc').style.zoom = zoom;
                        const fcSize = document.querySelector('.fc').offsetWidth;
                        if(fcSize>initialScreenSize) {
                            setTimeout(()=> {
                                const style = document.querySelector('.profile-calendar .fc-timegrid')?.style;
                                if(style) {
                                    style.setProperty('--rightoffset', `0px`);
                                    document.querySelector('.profile-calendar .fc-timegrid')?.classList.remove('with-scroll')
                                    document.querySelector('.scroll-indicator')?.classList.remove('with-scroll')
                                    document.querySelector('.profile-calendar .fc-timegrid')?.classList.add('with-scroll')
                                    document.querySelector('.scroll-indicator')?.classList.add('with-scroll')
                                    style.setProperty('animation', '')
                                }
                            }, 200);
                        }
                    }
                }, 650);


            })
        }
    }, [calendarRef])


    document.documentElement.classList.remove("nav-open");

    const updateEvent = useCallback(async (eventId, status)=>{
        await api.put(`bookings/${eventId}`, {status})
        await fetchBookings()
    }, [fetchBookings])

    const showEventPopup = ({title, descr, extendedProps, ...rest}) => {
        const {image, status, eventId} = extendedProps;
        const swalOpts = {
            imageUrl: image,
            imageHeight: 150,
            title: <span className={'h5'}>{title}</span>,
            html: <div>
                <strong>{intl.formatMessage({id: 'bookings.status'})}: {intl.formatMessage({id: `dialog.${status}`})}</strong>
                <p>{descr}</p>
            </div>,
            showCloseButton: true,
            showCancelButton: false,
            showDenyButton: true,
            focusConfirm: false,
            confirmButtonColor: '#66BB6A',
            cancelButtonColor: '#EF5350',
            confirmButtonText: <span>{intl.formatMessage({id: "common.accept"})}</span>,
            denyButtonText: <span>{intl.formatMessage({id: "schedule.cancel"})}</span>,
        }
        Swal.fire(swalOpts).then(({isConfirmed, isDenied})=>{
            if(isDenied) {
                updateEvent(eventId, 'canceled')
            }
        })

    }
    const renderSlotLabel = (slotLabel)=>{
        return <div className={'slot-label'}>{slotLabel.text}</div>
    }

    const navigate = useNavigate()

    const handleCancel = useCallback((booking, isTimeChange=false) => {
        const swalOpts = {
            icon: 'warning',
            title: <span className={'h5'}></span>,
            html: <div>
                {isTimeChange?intl.formatMessage({id: "common.change.time.question"}):intl.formatMessage({id: "schedule.areyousure"})}
            </div>,
            showCloseButton: true,
            showCancelButton: false,
            showDenyButton: true,
            focusConfirm: false,
            confirmButtonColor: '#66BB6A',
            cancelButtonColor: '#EF5350',
            confirmButtonText: <span>{intl.formatMessage({id:'schedule.cancel'})}</span>,
            denyButtonText: <span>{intl.formatMessage({id:'common.close'})}</span>,
        }
        Swal.fire(swalOpts).then(async ({isConfirmed, isDenied})=>{
            if(isConfirmed) {
                console.log('confirmed', booking.uuid)
                await api.delete(`bookings/${booking.uuid}`)
                await fetchBookings();
                if(isTimeChange) {
                    navigate(`/coach-profile/${booking.coachId}/grafikas`)
                }
            }
            if(isDenied) {
                console.log('canceled', booking.uuid)
            }
        })
    }, [locale]);

    const renderEventData = useCallback((event) => {
        const {schedule, scheduleData, status, uuid} = event.extendedProps;
        const coach = event.extendedProps.coach;
        const loc = coach.coachData.locations.find(d=>d.id===scheduleData.location)?.name;
        const act = user.dicts?.sports.find(d=>d.key===scheduleData.activity)?.name[intl.locale];

        return <Paper className={'text-justify'} elevation={4} style={{minWidth: 300}}>
            <Table>
                <TableRow>
                    <TableCell className={'font-weight-bolder'}>{intl.formatMessage({id: "schedule.datetime"})}</TableCell>
                    <TableCell align={'right'}>{moment(event.start).format('YYYY-MM-DD')}<br/>{moment(event.start).format('HH:mm')} - {moment(event.end).format('HH:mm')}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={'font-weight-bolder'}>{intl.formatMessage({id: "schedule.sport"})}</TableCell>
                    <TableCell align="right">{_.find(user.dicts?.sports, {key: scheduleData.activity})?.name[intl.locale]}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={'font-weight-bolder'}>{intl.formatMessage({id: "calendar.location"})}</TableCell>
                    <TableCell align="right">{loc}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={'font-weight-bolder'} style={scheduleData.comment?{}:{border: 'none'}}>{intl.formatMessage({id: "bookings.price"})}</TableCell>
                    <TableCell style={scheduleData.comment?{}:{border: 'none'}} align="right">&euro;{scheduleData.price}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={'font-weight-bolder'} style={scheduleData.comment?{}:{border: 'none'}}>{intl.formatMessage({id: "common.coach"})}</TableCell>
                    <TableCell style={scheduleData.comment?{}:{border: 'none'}} align="right">{coach.fullname}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={'font-weight-bolder text-capitalize'} style={scheduleData.comment?{}:{border: 'none'}}>{intl.formatMessage({id: "common.attendees"})}</TableCell>
                    <TableCell style={{border: 'none'}} align="right">{scheduleData.bookings.length} <FormattedMessage
                        id={"common.of"}/> {scheduleData.maxUsers}</TableCell>
                </TableRow>
            </Table>
            {scheduleData.comment && ( <div className={'p-2'}>
                    <div className={'font-weight-bolder'}>{intl.formatMessage({id: "common.comment"})}:</div>
                    <div className={'comments-box'} dangerouslySetInnerHTML={{__html: scheduleData.comment}}></div>
                </div>
            )}
        </Paper>
    }, [locale, intl])

    const renderEvent = ({event}) => {
        console.log('render', event)
        const {scheduleData, status, uuid} = event.extendedProps;
        const numBookings = event.extendedProps.bookings?.length || 0;
        const isFull = ((scheduleData.maxUsers || 1) - numBookings) < 1;
        const coach = event.extendedProps.coach
        const activity = user.dicts?.sports.find(d=>d.key===scheduleData.activity)?.name[intl.locale]
        const employee = coach.coachData.employees.find(d=>d.id===scheduleData.employee)?.name || coach.fullname || coach.name
        const location = coach.coachData.locations.find(d=>d.id===scheduleData.location)?.name
        const descr = <>{intl.formatMessage({id: "common.sport"})}: {activity}<br/>
            {intl.formatMessage({id: 'common.coach'})}: {employee}<br/>
            {intl.formatMessage({id: 'forms.book.location'})}: {location}</>

        const cancellable = moment(event.start).isAfter(moment()) && (coach.cancelRule===false || (moment(event.start).isAfter(moment().add(1,'d'))));

        return event._context.getCurrentData().currentViewType.indexOf('list')!==-1?(
          <div className={'d-flex'}>
            <Grid container onClick={()=>null/*()=>showEventPopup({...event, descr, extendedProps: event.extendedProps})*/}>
                <Grid item px={2} sx={{paddingBottom: {xs: 3}}} style={{alignSelf: 'center', textAlign: 'center'}} xs={12}  lg={3} xl={2}>
                    <MDAvatar
                        src={event.extendedProps.image}
                        alt={event.title}
                        size={'xxl'}
                        sx={{margin: 'auto'}}
                    />
                </Grid>
                <Grid item  xs={12} lg={6} xl={8} >
                    <div>
                        <MDTypography className={''}
                                      style={{lineHeight: 1.25}} component={'div'} variant={'subtitle2'}>
                            <Grid container spacing={1}>
                                <Grid item xs={6} className={'p-2 border-dark border-bottom-025'}>
                                    <FormattedMessage id={"schedule.sport"}/>
                                </Grid>
                                <Grid xs={6} className={'p-2 border-dark border-bottom-025'} item display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
                                    <strong>{activity}</strong>
                                </Grid>
                                <Grid item xs={6} className={'p-2 border-dark border-bottom-025'}>
                                    <FormattedMessage id={"calendar.coach"}/>
                                </Grid>
                                <Grid xs={6} className={'p-2 border-dark border-bottom-025'} item display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
                                    <strong>{employee} <MDButton onClick={()=>document.location.href='/coach-profile/'+coach.uuid+'/grafikas'} color={'violet'} iconOnly={true} size={'small'}><SearchIcon/></MDButton></strong>
                                </Grid>
                                <Grid item xs={6} className={'p-2 border-dark border-bottom-025'}>
                                    <FormattedMessage id={"calendar.location"}/>
                                </Grid>
                                <Grid xs={6} className={'p-2 border-dark border-bottom-025'} item display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
                                    <strong>{location}</strong>
                                </Grid>
                                <Grid item xs={6} className={'p-2 border-dark border-bottom-025'}>
                                    <FormattedMessage id={"bookings.price"}/>
                                </Grid>
                                <Grid xs={6} className={'p-2 border-dark border-bottom-025'} item display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
                                    <strong>&euro;{scheduleData.price}</strong>
                                </Grid>
                                <Grid item xs={6} className={'p-2'} >
                                    <FormattedMessage id={"bookings.status"}/>
                                </Grid>
                                <Grid className={'p-2'} xs={6} item display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
                                    <strong>{statusesDict.find(s=>s.status===status)?.label}</strong>
                                </Grid>
                                {event.extendedProps.declineComment && (<Grid item xs={6}  className={'p-2 border-dark border-top-025'} >
                                    {intl.formatMessage({id: "bookings.coach.comment"})}
                                </Grid>)}
                                {event.extendedProps.declineComment && ( <Grid xs={6} item display="flex" className={'p-2 border-dark border-top-025'}
                                                                         flexDirection="column" alignItems="flex-start" justifyContent="center">
                                    <div className={'text-muted'}><i>{event.extendedProps.declineComment}</i></div>
                                </Grid>)}
                            </Grid>
                        </MDTypography>
                    </div>
                </Grid>
                <Grid item xs={12} md={12} lg={3} xl={2} className={'d-flex justify-content-center flex-column p-2'} style={{width: 200}}>
                    {cancellable && status!=='declined' && <MDButton onClick={()=>handleCancel(event.extendedProps, true)} className={'text-white'} variant={'contained'} color={'secondary'}>{intl.formatMessage({id: "common.change.time"})}</MDButton>}
                    {cancellable && status!=='declined' && <MDButton onClick={()=>handleCancel(event.extendedProps, false)} className={'text-white mt-2 mx-auto'} style={{width: 120}} variant={'contained'} color={'error'} size={'small'}>{intl.formatMessage({id: "schedule.cancel"})}</MDButton>}
                </Grid>
            </Grid>

            </div>
        ):(
            <Tooltip
                followCursor={false}
                enterDelay={2000}
                enternextDelay={3000}
                classes={{
                    popper: 'grid-tooltip',
                    tooltip: 'bg-transparent p-0 m-0 border-0 mt-4',
                    arrow: 'arrow-white',
                }}
                title={renderEventData(event)}>
            <Grid container className={'h-100 p-1 cursor-pointer bookings-event-container'} onClick={()=>null/*()=>showEventPopup({...event, descr, extendedProps: event.extendedProps})*/}>
                <Grid item sm={9}>
                    <MDTypography component={'div'} style={{lineHeight: 1.1}} variant={'overline'}>
                        {moment(event.start).format("HH:mm")}<span
                        className={'d-none d-lg-inline'}> - {moment(event.end).format("HH:mm")}</span>
                    </MDTypography>

                    <div className={'d-sm-flex d-md-none align-items-baseline'}>
                        <MDTypography className={'text-bottom trim-vertical trim-vertical-3 text-event-desc'}
                                      style={{lineHeight: 1}} component={'div'} variant={'subtitle2'}>
                            {activity}
                        </MDTypography>
                    </div>
                    <div className={'d-none d-md-flex align-items-baseline'}>
                        <MDTypography className={'text-bottom trim-vertical trim-vertical-3 text-event-desc'}
                                      style={{lineHeight: 1, zoom: 0.7}} component={'div'} variant={'subtitle2'}>
                            {descr}
                        </MDTypography>
                    </div>
                </Grid>
                <Grid item sm={3} className={'d-none d-md-flex align-items-baseline'}>
                    <MDAvatar
                        src={event.extendedProps.image}
                        alt={event.title}
                        size={'sm'}
                        sx={{position: 'absolute', top: 2, right: 2}}
                    />
                </Grid>

            </Grid>
            </Tooltip>
        )
    }


    return (
        <>
            <ExamplesNavbar/>
            <div style={{height: '100%', marginTop: 140, minWidth: 380, minHeight: 17*60}} className={'px-0 px-sm-1 px-md-3 px-lg-5'}>
                <div  className={"my-5 px-0 px-lg-2 px-xl-5 client-bookings"}  style={{height: '100%', minHeight: 17*60}}>

                    <Calendar
                        cardHeight={minCardHeight}
                        themeSystem={'bootstrap5'}
                        events={events}
                        passRef={getRef}
                        header={{title: intl.formatMessage({id: `calendar.header.${user.isBusiness?'coach':'client'}`})}}
                        height={'100%'}
                        initialView={'listWeek'}
                        allDaySlot={false}
                        noAlert={true}
                        timeZone={'Europe/Vilnius'}
                        slotDuration={'01:00'}
                        slotMinTime={'06:00'}
                        slotMaxTime={'23:00'}
                        expandRows={true}
                        stickyHeaderDates={true}
                        slotMinWidth={100}
                        dayMinWidth={100}
                        buttonText={{
                            listWeek: intl.formatMessage({id: "bookings.list"}),
                            listMonth: intl.formatMessage({id: "bookings.list"}),
                            listYear: intl.formatMessage({id: "bookings.list"}),
                            timeGridWeek: intl.formatMessage({id: "bookings.grid"}),
                            today: intl.formatMessage({id: "bookings.thisweek"})
                        }}
                        headerToolbar={{
                            start: 'title',
                            center: '',
                            end: 'listWeek,timeGridWeek,today,prev,next'
                        }}
                        slotLabelFormat={(date)=>{
                            return `${date.start.hour.toString().padStart(2,'0')}:00 - ${(date.start.hour+1).toString().padStart(2,'0')}:00`
                        }}
                        eventTimeFormat={(date)=>{
                            return `${date.start.hour.toString().padStart(2,'0')}:00 - ${(date.start.hour+1).toString().padStart(2,'0')}:00`
                        }}
                        eventContent={renderEvent}
                        slotLabelContent={initialScreenSize>1200?renderSlotLabel:false}
                    />
                </div>

            </div>


            <DemoFooter/>
        </>
    );
}

export default CalendarPage;
